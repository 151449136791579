import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/config";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import {
    Container,
    Row,
    Col,
    Form,
    Spinner
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import { LoginUserPhase2Action, sendOtpForMobileVerificationAction } from "../../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import Web3 from "web3";
import { Select } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import LoginSignUpHeader from "../../directives/loginsingupheader";


const Login = () => {
    const navigate = useNavigate();

    const [form, setForm] = useState({ contactMethod: 'email', contact: '', password: '' });
    const [islogin, setislogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [validationError, setvalidationError] = useState({ contactError: '', passwordError: '' });
    const [loading, setLoading] = useState(false); // Loading state




    const inputHandler = (e) => {
        const { name, value, id } = e.target
        console.log(name);
        setForm((old) => {
            return { ...old, [name]: value }
        })
    }

    const handlePhoneChange = (value, data) => {
        setForm((old) => ({
            ...old,
            countryCode: `+${data.dialCode}`,
            contact: value.slice(data.dialCode.length)
        }));
    };

    const userLoginAction = async () => {
        setLoading(true); // Start loading

        let errors = { contactError: '', passwordError: '' };
    
        // Trim spaces from input fields
        const trimmedContact = form.contact.trim();
        const trimmedPassword = form.password.trim();
    
        // Check for empty fields
        if (trimmedContact === '') {
            errors.contactError = 'Email is required.';
            setLoading(false);
        }
        if (trimmedPassword === '') {
            errors.passwordError = 'Password is required.';
            setLoading(false);
        }
    
        // Check for spaces
        if (form.contact.includes(' ')) {
            errors.contactError = 'Email cannot contain spaces.';
            setLoading(false);

        }
        if (form.password.includes(' ')) {
            errors.passwordError = 'Password cannot contain spaces.';
            setLoading(false);

        }
    
        setvalidationError(errors);
    
        // Exit if there are errors
        if (errors.contactError || errors.passwordError) return;
    
        try {
            let res = await LoginUserPhase2Action({ contact: trimmedContact, password: trimmedPassword,contactMethod :form.contactMethod });
            if (res.success) {
                toast.success(res.msg);
                setLoading(false); 

                Cookies.set("loginSuccessStepMrMint", JSON.stringify(res.data));
                setTimeout(() => {
                    window.location.href = `${config.baseUrl}dashboard`;
                }, 1000);
            } else {
                if (res.msg.toLowerCase().includes("email")) {
                    setvalidationError({ contactError: res.msg, passwordError: '' });
                    setLoading(false);
                    
                } else if (res.msg.toLowerCase().includes("password")) {
                    setvalidationError({ contactError: '', passwordError: res.msg });
                    setLoading(false);
                } else {
                    setvalidationError({ contactError: '', passwordError: res.msg });
                    setLoading(false);
                }
                setLoading(false); 
            }
        } catch (error) {
            setLoading(false); 
            console.error(error);
        }
    };
    

    const connectMetasmaskForAddress = async (e) => {
        // setLoading(true); // Start loading

        e.preventDefault()
        try {
            if (window.ethereum) {
                setislogin(true);
                var web3 = new Web3(window.ethereum);
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                console.log(accounts, "///", window.ethereum);
                let chainId = await web3.currentProvider.chainId;
                if (!chainId) {
                    chainId = web3.currentProvider.getChainId();
                }
                if (chainId != config.chainId && web3.utils.toHex(parseInt(chainId)) != config.chainId) {
                    toast.error(`Please select BNB smartchain!!`);
                    setislogin(false);
                    return false;
                } else {
                    console.log(accounts[0]);

                    if (accounts[0]) {
                        let publicAddress = accounts[0];
                        const { signature } = await new Promise((resolve, reject) =>
                            web3.eth.personal.sign(
                                web3.utils.fromUtf8(`Login Mrmint`),
                                publicAddress,
                                (err, signature) => {
                                    if (err) return reject(err);
                                    return resolve({ publicAddress, signature });
                                }
                            )
                        );
                        let res = await LoginUserPhase2Action({
                            address: accounts[0],
                            signature: signature,
                        });
                        console.log("test3");

                        if (res.success) {

                            setislogin(false);
                            // setLoading(false); 

                            toast.success(res.msg);
                            Cookies.set("loginSuccessStepMrMint", JSON.stringify(res.data));
                            setTimeout(() => {
                                // navigate(`${config.baseUrl}dashboard`)
                                window.location.href = `${config.baseUrl}dashboard`;
                            }, 2000);
                        } else {
                            setislogin(false);
                            toast.error(res.msg);
                        }
                    }
                }
            } else {
                setislogin(false);
                toast.error("Please use Dapp browser!!");
            }
        } catch (error) {
            console.log('error', error);

            setislogin(false);
            switch (error.code) {
                case 4001:
                    toast.error("User Rejected");
                    break;
                case -32002:
                    toast.error(
                        "Request Already Processing, Please check your Meta Mask wallet"
                    );
                    break;
                case "ERR_NETWORK":
                    toast.error("We're experiencing technical difficulties.");
                    break;
                default:
                    break;
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }; 


    return (
        <>
            <LoginSignUpHeader />
            <Toaster />
            <section className="position-relative overflow-hidden signup">
                <div className="shape_bg">
                    <img src="assets/images/shape.svg" className="shape" />
                    <img src="assets/images/shape1.svg" className="shape1" />
                    <img src="assets/images/shape2.svg" className="shape2" />
                </div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={5} className="">
                            <Zoom>
                                <div className="signup_form">
                                    <Container className="">
                                        <Form >
                                            <Row>
                                                <Col lg={12} className="text-center mb-2">

                                                    <div>
                                                        <h3 className="text-white">Login</h3>
                                                    </div>
                                                </Col>
                                                <Col lg={12} className="mb-3 d-flex gap-4 flex-wrap">
                                                    {/* <Form.Label>Sign In with Email </Form.Label> */}
                                                    <div className="d-flex gap-4">
                                                        {/* <div class="form-chec text-light d-flex align-items-center">
                                                            <input class="form-check-input" type="radio" name="flexRadioDefault" className="me-2" style={{ width: 15, height: 15 }} id="flexRadioDefault1" checked={form.contactMethod === 'email'}
                                                                onChange={() => setForm((old) => ({ ...old, contactMethod: 'email', contact: '' }))} />
                                                            <label class="form-check-label" for="flexRadioDefault1">
                                                                Email
                                                            </label>
                                                        </div> */}
                                                        {/* <div class="form-chec text-light d-flex align-items-center">
                                                            <input class="form-check-input" type="radio" name="flexRadioDefault2" className="me-2" style={{ width: 15, height: 15 }} id="flexRadioDefault1" checked={form.contactMethod === 'phone'}
                                                                onChange={() => setForm((old) => ({ ...old, contactMethod: 'phone', contact: '' }))} />
                                                            <label class="form-check-label" for="flexRadioDefault2">
                                                                Phone
                                                            </label>
                                                        </div> */}
                                                    </div>

                                                </Col>
                                                {/* {form.contactMethod == 'email' ? ( */}
                                                <Col lg={12} className="mb-3">
                                                    <Form.Group controlId="contactInput">
                                                    <Form.Label>Email address</Form.Label>
                                                        <Form.Control
                                                            type={form.contactMethod === 'email' ? "email" : "text"}
                                                            onChange={inputHandler}
                                                            name="contact"
                                                            placeholder="Enter your email address"
                                                            value={form.contact}
                                                        />
                                                    </Form.Group>
                                                    <span className="validationError">{validationError.contactError}</span>
                                                </Col>
                                                <Col lg={12}>
                                                <Form.Label>Password</Form.Label>
                                                    <div className="position-relative">
                                                        <input
                                                            type={showPassword ? "text" : "password"}
                                                            className="form-control password-input"
                                                            onChange={inputHandler}
                                                            name="password"
                                                            placeholder="Enter your password"
                                                        />
                                                        <div
                                                            className="eye-icon2"
                                                            onClick={togglePasswordVisibility}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                        </div>
                                                    </div>
                                                    <span className="validationError">{validationError.passwordError}</span>
                                                </Col>

                                                <Col lg={12} className=" mb-0">
                                                    <Row>
                                                        <Col lg={6}>
                                                            {/* <label class="container_checkbox">Remember me?
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label> */}
                                                        </Col>
                                                        <Col lg={6} className="text-right my-2 ">
                                                            <a href={`${config.baseUrl}forgetpassword`} className="custom-a"><span className="custom-a">Forgot your Password?</span></a>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col lg={12} className="mt-2">
                                                    <Row>
                                                        <Col lg={12} className="mb-3 text-center">
                                                            <div>
                                                            <button
                                                                    type="button"
                                                                    onClick={userLoginAction}
                                                                    className="text-white px-5 btn btn-main2 custom-width"
                                                                    disabled={loading} // Disable button while loading
                                                                >
                                                                    {loading ? (
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    ) : (
                                                                        "Login"
                                                                    )}
                                                                </button>
                                                            </div>
                                                            <p className="text-light-grey mt-3 mb-3">or </p>
                                                            <div>
                                                                <button
                                                                    className="text-white   btn btn-main2  custom-width"
                                                                    onClick={connectMetasmaskForAddress}
                                                                >
                                                                    {/* <img alt="" src="assets/images/metamask_icon.png" height="28px"></img> &nbsp; */}
                                                                    Connect Wallet
                                                                </button>
                                                                <p className=" mt-3 mb-0">Don’t have an account? <a href={`${config.baseUrl}signup`}><span className="text-gradient"> Click here to sign up</span></a></p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Container>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </>
    );
};
export default Login;
