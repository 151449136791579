import React from "react";
import "../Stepmintmobile/css/stepmintmobile.css";

import { Container, Row, Col } from "react-bootstrap";
import { Zoom } from "react-reveal";
import "../Stepmintmobile/css/mobilefooter.css";


const Stepmint = () => {
  return (
    <>

      <section className="position-relative text-white overflow-hidden py-0">
        <Container>

          {/* <Row className="justify-content-center">
            <Col lg={4} className=" ">
              <Container>

                <h6 className="my-5 textcolors fw-bold"><span className="pe-4">
                  <IoChevronBackOutline />
                </span> How Stepmint Works</h6>
                <Howitimage className="imgbox" />
              </Container>
            </Col>
          </Row> */}

          <Row className="justify-content-center">
            <Col lg={4} className=" box_list">
              <Container>
                {/* <div>
                  <span>How Stepmint Works</span>
                </div> */}

              </Container>
              <Container className="mt-4 walkbox">
                <Zoom>

                  <p className="my-4"> Walk your way to crypto riches with STEPMINT! This app pays you real cryptocurrency i.e MNT Tokens, just for strolling.  </p>

                  <p className="my-4">STEPMINT uses sensors to track your steps. Download, create an account, and let the magic begin. </p>

                  <p className="my-4">Whether you carry your phone or sync it with a fitness tracker, hit your daily step goal and watch your MNT tokens grow.</p>

                  <p className="mt-4 mb-1">Here, fitness meets finance in the most delightful way possible!</p>

                </Zoom>
              </Container>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={4} className=" box_list">
              <Container className="my-5">
                <Zoom>
                  <div className="introduc_box text-center">
                    <h4 className="">INTRODUCING STEPMINT</h4>
                    <h6 className="text-center fw-600 mt-3">Our Path To Earning Crypto With Every Step!</h6>

                    <p className="mb-0">STEPMINT is more than just a step tracking app; it's a Web 3.0-powered revolution that rewards you for leading an active
                      Lifestyle.
                    </p>

                  </div>
                </Zoom>
              </Container>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={4} className="mt-4 ">
              <Container>
                <img src="assets/images/dailystepimg.png" className="dailystepimg" />
              </Container>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={4} className="mt-4 box_list">
              <Container>
                <div>
                  {/* <span>DAILY STEP GOALS</span> */}
                  <h6 className="mt-2 textcolors fw-bold">DAILY STEP GOALS</h6>
                </div>
              </Container>
              <Container className="mt-3">
                <Zoom>
                  <p>  STEPMINT encourages you to set and achieve daily step goals. Challenge yourself and watch your crypto assets grow while  improving your health.
                  </p>
                  <h6 className="textcolors fw-bold mt-4">MOVE-TO-EARN</h6>
                  <p className="mb-0">To earn the rewards its mandatory to:</p>
                  <p>You must have the Stepmint NFT Subscription Plan.
                    Start the “Step Counter” and perform the moving activity(walk, jog or run).
                    Note: If you don’t have the Stepmint NFT Subscription Plan, you could use the app to count your steps but won’t be able to earn the rewards.
                  </p>

                  <h6 className="textcolors fw-bold mt-4">Step Counter</h6>
                  <h6>Here's how Stepmint's 'Step Count' works:</h6>
                  <h6>6 Hours window</h6>
                  <p>Once you start the “Step Counter”, you have 6 hours max. time.
                    This 6 hours will be counted continuously without any break from the moment you activate the “Step Counter”.
                  </p>
                  <h6 className="textcolors fw-bold mt-4">Terminating the app/Removing the App from Background</h6>
                  <p>Make sure to not terminate the app from the background, doing such will deactivate the “Step Counter”. Please note that terminating the app won’t stop the 6 hour window. </p>
                  <h6 className="textcolors fw-bold mt-4">Renewal of 6 Hours window</h6>
                  <p>The “6 hour window” renews every 24 hours starting from “00:00” till “23:59”. Make sure to start your “Stepmint Run” by 18:00 to utilise the whole 6 hours window.</p>
                </Zoom>
              </Container>
            </Col>
          </Row>


          <Row className="justify-content-center">
            <Col lg={4} className="mt-5 ">
              <Container>
                <img src="assets/images/effortimg.png" className="effortimg" />
              </Container>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={4} className="mt-3 box_list">
              <Container>
                <div>
                  {/* <span>EFFORTLESS REWARD EARNING</span> */}
                  <h6 className="textcolors fw-bold mt-3">EFFORTLESS REWARD EARNING</h6>
                </div>
              </Container>
              <Container className="mt-3">
                <Zoom>
                  <p>  As you go about your day, every step you take adds to your crypto rewards. No need to perform complex tasks or
                    engage in exhausting activities! As Stepmint makes your earnings super effortless.
                  </p>

                </Zoom>
              </Container>
            </Col>
          </Row>


          <Row className="justify-content-center">
            <Col lg={4} className="mt-5 ">
              <Container>
                <img src="assets/images/accurateimg.png" className="accurateimg" />
              </Container>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={4} className="mt-3 box_list">
              <Container>
                <div>
                  {/* <span>ACCURATE STEP TRACKING</span> */}
                  <h6 className="textcolors fw-bold mt-3">ACCURATE STEP TRACKING</h6>

                </div>
              </Container>
              <Container className="mt-3">
                <Zoom>
                  <p>  Whether your device is in your pocket or synced with a fitness tracker, STEPMINT accurately tracks your daily steps. It's
                    your pedometer, ensuring every step counts towards your rewards.

                  </p>

                </Zoom>
              </Container>
            </Col>
          </Row>


          <Row className="justify-content-center">
            <Col lg={4} className="mt-5 ">
              <Container>
                <img src="assets/images/secureimg.png" className="secureimg" />
              </Container>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={4} className="mt-3 box_list">
              <Container>
                <div>
                  {/* <span>SECURE AND TRANSPARENT REWARDS</span> */}
                  <h6 className="textcolors fw-bold mt-3">SECURE AND TRANSPARENT REWARDS</h6>

                </div>
              </Container>
              <Container className="mt-3">
                <Zoom>
                  <p>  Your crypto assets are securely stored, and the rewards system operates on a transparent and decentralized blockchain network. You can monitor your earnings with confidence.
                  </p>

                </Zoom>
              </Container>
            </Col>
          </Row>


          <Row className="justify-content-center mb-5">
            <Col lg={4} className="mt-4 box_list">
              <Container className="mt-4">
                <Zoom>
                  <div className="introduc_box text-center">
                    <p>Step out of your comfort zone to experience the best of both worlds: fitness and cryptocurrency! </p>

                    <p>We are revolutionizing crypto with a fitness twist! It's a user-friendly and innovative app that aligns your physical well-being with financial growth. </p>
                    {/* <p className="mb-0">So, why wait? Download STEPMINT today, start tracking your steps, and watch your crypto assets flourish with each stride!</p> */}

                  </div>
                </Zoom>
              </Container>
            </Col>
          </Row>

        </Container>

      </section>

      {/* <Mobilefooter /> */}


    </>
  );
};
export default Stepmint;
