import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap';
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { verifyOtpForEmailVerificationAction, verifyOtpForMobileVerificationAction } from '../../Action/user.action';

const SignUpEmailVerificationModal = ({ showModal, setshowModal, countryCode, email, token, mobile,timer, setTimer, setIsTimerActive, isTimerActive, verificationStatus, handleVerifyMobile, handleVerifyEmail }) => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpIs, setOtpIs] = useState('');
    const inputRefs = useRef([]);
    const loginData = Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
        ? JSON.parse(Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" }))
        : [];

    const handleOtpChange = (value, index) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value.length === 1 && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && otp[index] === '' && index > 0) {
            const newOtp = [...otp];
            newOtp[index - 1] = '';
            setOtp(newOtp);
            inputRefs.current[index - 1].focus();
        }
    };

    useEffect(() => {
        setOtpIs(otp.join(''));
    }, [otp]);

    useEffect(() => {
        if (showModal) {
            setTimer(timer);
            setIsTimerActive(true);
        }
    }, [showModal]);

    useEffect(() => {
        let countdown;
        if (isTimerActive && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(countdown);
            setIsTimerActive(false);
        }
        return () => clearInterval(countdown);
    }, [isTimerActive, timer]);

    const verifyOtpForVerification = async () => {
        try {
            const data = { otp: otpIs, token };
            const res = verificationStatus === 'email'
                ? await verifyOtpForEmailVerificationAction(data)
                : await verifyOtpForMobileVerificationAction(data);

            if (res.success) {
                toast.success(res.msg);
                loginData.is_email_verify = 1;
                const jsonString = JSON.stringify({
                    email: email,
                    emailVerify: res.data[0].emailVerified,
                    ...(verificationStatus === 'email' ? {} : {
                        phone: mobile,
                        countryCode:countryCode,
                        phoneVerified: res.data[0].phoneVerified
                    }),
                    token: res.data[0].token
                });

                localStorage.setItem('userData', jsonString);
                setshowModal(false);
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Modal show={showModal} onHide={() => setshowModal(false)} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">
                        <h5 className="mb-0">OTP Verification</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className='d-flex flex-column justify-content-center align-items-center'>
                        {verificationStatus === 'email' ? (
                            <p className='text-center'>Enter the code that we sent to your email: {email}</p>
                        ) : (
                            <p className='text-center'>Enter the code that we sent to your phone number : +{countryCode} {mobile}</p>
                        )}
                        <Row className="mb-4 d-flex justify-content-center">
                            {otp.map((value, index) => (
                                <Col key={index} xs={2} className='d-flex justify-content-center align-items-center'>
                                    <Form.Control
                                        type="text"
                                        maxLength="1"
                                        value={value}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        className='text-center'
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (/^\d*$/.test(inputValue)) {
                                                handleOtpChange(inputValue, index);
                                            }
                                        }}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <p className="text-center mb-4">Time remaining: {timer} seconds</p>
                        <Button
                            variant="main2"
                            className="text-white w-50"
                            style={{ height: 45 }}
                            onClick={isTimerActive ? verifyOtpForVerification : () => {
                                setIsTimerActive(true);
                                verificationStatus === 'email' ? handleVerifyEmail() : handleVerifyMobile();
                            }}
                        >
                            {isTimerActive ? "Verify OTP" : "Resend OTP"}
                        </Button>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SignUpEmailVerificationModal;
