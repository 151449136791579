import Cookies from "js-cookie";
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import config from "../config/config";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteUserProfileAction } from "../Action/user.action";
import toast from "react-hot-toast";

export default function DeleteUser() {
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
        Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
      );

  let { authToken } = useParams();
  console.log(authToken);

  useEffect(() => {
    if (!loginData.id 
      // || loginData.authToken != authToken
      ) {
      toast.error("Please Connect to your registered wallet")
      
    }
    console.log(
      loginData.authToken,
      authToken,
      loginData.authToken != authToken
    );
  }, []);

  const deleteUserFunc = async () => {
    Swal.fire({
      title: `Account Deletion Confirmation      `,
      text: "Kindly confirm your intention to permanently delete your account. This action is irreversible and will result in the permanent removal of all associated data.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteUserProfileAction();
        if (res.success) {
          Swal.fire("Account Deletion Request Received!!", res.msg, "success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Card style={{ width: "50rem" }}>
          <Card.Body>
            <Card.Title>
              <div className="mb-4 d-flex justify-content-center">
                <h2 className="text-danger">Attention</h2>
              </div>
            </Card.Title>
            <Card.Title>
              <div className=" mb-4 d-flex justify-content-center">
                <h4 className="">Account Deletion Process</h4>
              </div>
            </Card.Title>
            <Card.Text>
              Please note that if you choose to delete your account, the process
              may take up to 7 working days. During this time, we ensure the
              secure removal of all your data. Your patience is appreciated.
            </Card.Text>
            <Card.Text>
              If you have any concerns, feel free to contact our support team ({" "}
              <a
                className="text-success mt-2 text-decoration-none"
                href="mailto:info@stepmint.io"
              >
                info@stepmint.io
              </a>
              ).
            </Card.Text>
            <Card.Text>Thank you</Card.Text>
            <div className="d-flex justify-content-center">
              <Button variant="secondary" onClick={deleteUserFunc}>
                Delete
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
