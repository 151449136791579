import React from 'react';
import { Helmet } from 'react-helmet';

const ContactUsMetaTag = () => {
  return (
    <Helmet>
         <meta charset="UTF-8" />
        <meta name="description" content="Get in touch with our customer support to know about the best walking app to earn money while being healthy. " />
        <meta name="title" content="Unlock Wealth and Wellness | Start Earning with Stepmint Today" />
        <meta name="keywords" content="app pays to walk, pay me to walk, walk for rewards, walking paid app, best walking app to earn money, walking app to earn money, earn money walking app" />
        <title>app pays to walk|pay me to walk|walk for rewards|walking paid app</title>
      {/* Organization JSON-LD */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "StepMint",
          "alternateName": "StepMint",
          "url": "https://stepmint.io/",
          "logo": "https://stepmint.io/assets/images/logo.png",
          "sameAs": [
            "https://www.facebook.com/profile.php?id=61551542573562",
            "https://twitter.com/step_mint",
            "https://instagram.com/stepmint?igshid=MzMyNGUyNmU2YQ==",
            "https://www.linkedin.com/company/stepmint/about/?viewAsMember=true"
          ]
        }
        `}
      </script>

      {/* Website JSON-LD */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "StepMint",
          "url": "https://stepmint.io/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "{search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }
        `}
      </script>

      {/* Meta Tags */}
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content="app pays to walk|pay me to walk|walk for rewards|walking paid app" />
      <meta property="og:url" content="https://stepmint.io/contactus" />
      <meta property="og:image" content="https://stepmint.io/assets/images/contactus_banner.jpg" />
      <meta property="og:description" content="Get in touch with our customer support to know about the best walking app to earn money while being healthy." />
      <meta property="business:contact_data:street_address" content="322, Magneto Offizo, Magneto Mall" />
      <meta property="business:contact_data:locality" content="Raipur" />
      <meta property="business:contact_data:region" content="Chhattisgarh" />
      <meta property="business:contact_data:postal_code" content="492001" />
      <meta property="business:contact_data:country_name" content="India" />

      {/* Website Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="app pays to walk|pay me to walk|walk for rewards|walking paid app" />
      <meta property="og:url" content="https://stepmint.io/contactus" />
      <meta property="og:image" content="https://stepmint.io/assets/images/contactus_banner.jpg" />
      <meta property="og:description" content="Get in touch with our customer support to know about the best walking app to earn money while being healthy." />
    </Helmet>
  );
};

export default ContactUsMetaTag;
