import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Modal,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import "../directives/header.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../component/home.css";
import "../component/ldp.css";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";

import DataTable, { createTheme } from "react-data-table-component";
import { LDPUserlist, ldpListUpdateAction } from "../Action/user.action";
createTheme(
  "solarized",
  {
    text: {
      primary: "#141315",
      secondary: "#141315",
    },
    background: {
      default: "#fff",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#ccc",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const LDP = () => {
  const [show, setShow] = useState(false);
  const [ldplist, setldplist] = useState([]);
  const [filterldplist, setfilterldplist] = useState([]);
  const [userData, setuserData] = useState({
    name: "",
    email: "",
    mobile: "",
    isAttending: null,
    area: "",
  });
  const [validationError, setvalidationError] = useState({
    nameError: "",
    isAttendingError: "",
    mobileError: "",
    areaError: "",
  });

  const [masterldplist, setmasterldplist] = useState({});

  const handleClose = () => {
    setvalidationError({})
    setShow(false)
  };
  const handleShow = (id) => {
    console.log(id);
    if (!loginData.id) {
      toast.error("Please login first", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (loginData.id != id.userId) {
      toast.error("You can only update your details");
      return;
    } else if (id.isAttending != null) {
      toast.error("You have already filled your details");
      return;
    }
    setuserData(id);
    setShow(true);
  };

  function validate() {
    let nameError = "";
    let isAttendingError = "";
    let mobileError = "";
    let areaError = "";

    if (userData.name == "" || userData.name == " ") {
      nameError = "Name is required.";
    }
    if (userData.isAttending == null) {
      isAttendingError = "Select required field.";
    }
    if (userData.mobile.trim() === "") {
      mobileError = "Enter your mobile number.";
    } else if (!/^\+?\d+$/.test(userData.mobile.trim())) {
      mobileError = "Mobile number should contain only digits.";
    } else if (userData.mobile.trim().length < 6 || userData.mobile.trim().length > 18) {
      mobileError = "Mobile number length should be between 6 to 18 characters.";
    }
    if (userData.area.trim().length < 5) {
      areaError = "Address should be at least 5 characters long.";
    }
    if (isAttendingError || nameError || mobileError || areaError) {
      setvalidationError({
        isAttendingError,
        nameError,
        mobileError,
        areaError,
      });
      return false;
    } else {
      return true;
    }
  }
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint",)
    );

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        maxWidth: "20%",
        backgroundColor: "#f2f2f2",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#f2f2f2", // Match the background color of the header
      },
    },
  };
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      maxWidth: "8%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      maxWidth: "25%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      maxWidth: "29%",
    },
    {
      name: "Earning",
      selector: (row) => `$${row.earning}`,
      maxWidth: "30%",
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      maxWidth: "30%",
    },
     {
      name: "Interested",
      selector: (row) => row.mobile,
      maxWidth: "30%",
      cell: (row) => (
        <div
          className=""
        >
          {row.isAttending== 1 ? "Yes":row.isAttending== 0 ? "No":"Not decided"}
        </div>
      ),
    },
    // {
    //   name: "Wallet",
    //   selector: (row) => row.wallet,
    //   maxWidth: '24%',
    // },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <button
          className="btn btn-main2 btn-sm text-white rounded-1 py-2"
          onClick={() => handleShow(row)}
        >
          Action
        </button>
      ),
      maxWidth: "120px",
    },
  ];

  const inputHandlerForm = (e) => {
    const { name, value } = e.target;
    setuserData((old) => {
      return { ...old, [name]: value };
    });
  };

  const inputHandler = (e) => {
    const inputValue = e.target.value.toLowerCase();
    const filtered = filterldplist.filter((item) => {
      return (
        item.name.toLowerCase().includes(inputValue) ||
        item.email.includes(inputValue) ||
        String(item.remaining).includes(inputValue)
      );
    });
    setldplist(filtered);
  };

  const subHeaderComponent = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        type="search"
        id="outlined-basic"
        placeholder="Search"
        onChange={(e) => inputHandler(e)}
        style={{ margin: "5px", padding: "5px" }}
      />
    </div>
  );

  useEffect(() => {
    getldpdatalist();
    return () => { };
  }, []);

  const getldpdatalist = async () => {
    try {
      let res = await LDPUserlist();
      if (res.success) {
        setldplist(res.data.list);
        setfilterldplist(res.data.list);
        setmasterldplist(res.data.masterData[0]);
      } else {
        setldplist([]);
        setmasterldplist({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const inputHandlerFormCheck = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setuserData((old) => {
      return { ...old, [name]: value };
    });
  };

  const updatelpdlist = async (e) => {
    try {
      e.preventDefault();
      console.log(userData, "userdata");
      const isValid = validate();
      if (!isValid) {
        return;
      }
      let res = await ldpListUpdateAction(userData);
      console.log(res);
      if (res.success) {
        handleClose();
        toast.success(res.msg);

        getldpdatalist();
      } else {
        toast.success(res.msg);
        getldpdatalist();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <div className="lighttheme bg-white overflow-hidden py-5 ldp">
        <Container>
          <Row>
            <Col lg={12}>
              <h3 className="text-center">{masterldplist.name}</h3>
              <h5 className="text-center fw-lighter">
                <small>{masterldplist.ldpDate}</small>
              </h5>
              <div className="d-flex justify-content-between mt-4">
                <div className="fw-lighter">
                  Total Users- {masterldplist.userCount}
                </div>
                <div className="fw-lighter">
                  Interested- {masterldplist.interested}
                </div>
                <div className="fw-lighter">
                  Not-Interested- {masterldplist.notInterested}
                </div>
                <div className="fw-lighter">
                  Not-Decided- {masterldplist.notDecided}
                </div>
                <div className="fw-lighter">{masterldplist.eventDate}</div>
              </div>
              <DataTable
                columns={columns}
                data={ldplist}
                customStyles={customStyles}
                theme="solarized"
                pagination
                subHeader
                subHeaderAlign="right"
                subHeaderWrap
                subHeaderComponent={subHeaderComponent}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/* ------------ */}
      <Modal show={show} onHide={handleClose} className="ldp-modal">
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col lg={12} className="">
              <Form onSubmit={updatelpdlist}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    autoComplete="off"
                    autoFocus="off"
                    value={userData.name}
                    name="name"
                    onChange={inputHandlerForm}
                    disabled
                  />
                  <Form.Text className="validationError text-danger">
                    {validationError.nameError}
                  </Form.Text>

                  {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text> */}
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  id="customemaild"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    autoComplete="off"
                    autoFocus="off"
                    className="text-black"
                    value={userData.email}
                    disabled
                  />

                  {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Mobile No</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Mobile No"
                    autoComplete="off"
                    autoFocus="off"
                    className="text-black"
                    value={userData.mobile}
                    onChange={inputHandlerForm}
                    name="mobile"
                  />
                  <Form.Text className="validationError text-danger">
                    {validationError.mobileError}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <FloatingLabel controlId="floatingTextarea2" label="Address">
                    <Form.Control
                      as="textarea"
                      placeholder="Enter your address"
                      style={{ height: "100px" }}
                      onChange={inputHandlerForm}
                      name="area"
                      value={userData.area}
                    />
                    <Form.Text className="validationError text-danger">
                      {validationError.areaError}
                    </Form.Text>
                  </FloatingLabel>
                </Form.Group>
                <div className="mb-3">
                  <Form.Label>Do you want to participate in the Program?</Form.Label>
                  <div className="d-flex">
                    <label className="agree_disagree w-50 me-2">
                      <div>
                        <input
                          type="radio"
                          name="isAttending"
                          value={1}
                          onChange={inputHandlerFormCheck}
                        />
                        <span className="ms-2">Yes</span>
                      </div>
                    </label>
                    <label className="agree_disagree w-50">
                      <div >

                        <input
                          type="radio"
                          name="isAttending"
                          value={0}
                          onChange={inputHandlerFormCheck}
                        />
                        <span className="ms-2">No</span>

                      </div>
                    </label>
                  </div>
                  <Form.Text className="validationError text-danger">
                    {validationError.isAttendingError}
                  </Form.Text>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  className="btn btn-main2 w-100 me-2 rounded-1"
                >
                  Submit
                </Button>
                {/* <Button variant="primary" type="submit" className="btn btn-main2 rounded-1">
                  Disagree
                </Button> */}
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};
export default LDP;
