import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import Header from "../../directives/header";
import toast, { Toaster } from "react-hot-toast";
import { SendOtpAction, VerifyOtpAction } from "../../Action/user.action";
import { Link } from "react-router-dom";


const LdpQualifier = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [resendBtn, setReSendBtn] = useState(false)
  const [error,setError] = useState("")

  const handleSendOtp = async() => {
    let obj = {
        email
    }
    let res = await SendOtpAction(obj);
    if (res.success) {
        setIsOtpSent(true);
        setReSendBtn(true)
        toast.success("OTP sent to your email!");
    }else{
        toast.error(res.msg);
        setError(res?.msg)
    }
  };

  const handleVerifyOtp = async() => {
    let obj ={
        email,
        otp
    }
    let res = await VerifyOtpAction(obj);
    if (res.success) {
      toast.success("OTP verified successfully!");
      setEmail('')
      setOtp('')
      setIsOtpSent(false)
      setReSendBtn(false)
    } else {
      toast.error("Invalid OTP, please try again.");
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setOtp(value);
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Container className="d-flex justify-content-center align-items-center">
          <Row className="justify-content-md-center">
            <Col md="12">
              <Card className="p-4">
                <Card.Body>
                  <h3 className="text-center mb-4">LDP User Verification!</h3>
                  <Form>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter a valid email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                     
                    </Form.Group>
                    {resendBtn && <Link style={{color:'white'}} className="d-flex align-item-end justify-content-end mt-1" onClick={handleSendOtp}>Resend OTP</Link>}
                    {isOtpSent && (
                      <Form.Group controlId="formOtp" className="mt-3">
                        <Form.Label>OTP</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the OTP"
                          value={otp}
                          onChange={handleOtpChange}
                        />
                      </Form.Group>
                    )}
                    {!isOtpSent ? (
                      <Button variant="primary" onClick={handleSendOtp} className="mt-3 w-100">
                        Send OTP
                      </Button>
                    ) : (
                      <Button variant="success" onClick={handleVerifyOtp} className="mt-3 w-100">
                        Verify OTP
                      </Button>
                    )}
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LdpQualifier;
