import React from 'react';
import { Helmet } from 'react-helmet';

const SignupMetaTag = () => {
  return (
    <Helmet>
       {/* Meta Tags */}
       <meta name="title" content="earn money walking app|walking earning app|walk and earn app download" />
      <meta name="keywords" content="earn money walking app, walking earning app, walk and earn app download, apps to earn money for walking, apps to earn money by walking" />
      <meta name="description" content="Register on StepMint and get rewards by walking through the best walking earning app." />
      <title>earn money walking app|walking earning app|walk and earn app download</title>
      {/* Organization JSON-LD */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "StepMint",
          "alternateName": "StepMint",
          "url": "https://stepmint.io/",
          "logo": "https://stepmint.io/assets/images/logo.png",
          "sameAs": [
            "https://www.facebook.com/profile.php?id=61551542573562",
            "https://twitter.com/step_mint",
            "https://instagram.com/stepmint?igshid=MzMyNGUyNmU2YQ==",
            "https://www.linkedin.com/company/stepmint/about/?viewAsMember=true"
          ]
        }
        `}
      </script>

     
    </Helmet>
  );
};

export default SignupMetaTag;
