import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Button, Card, Tab, Nav, Modal } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "../directives/header.css";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../component/home.css";
import "../component/newhome.css";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { RegisterAction } from "../Action/user.action";
import Web3 from "web3";
import { Helmet } from "react-helmet";

const Home = () => {
  const [show, setShow] = useState(false);
  const [islogin, setislogin] = useState(false);

  const navigate = useNavigate();


  const handleClose = () => {
    setShow(false);
  }

  const navigateToSignup = () => {
    setShow(false)
    setislogin(false)
    navigate(`${config.baseUrl}signup`)
  }

  const navigateToPackagePlan = () => {
    // alert(1)
    setShow(false)
    setislogin(false)
    // navigate(`${config.baseUrl}packageplan`)
    window.open("https://chat.whatsapp.com/Ej1YY6yXsxmKfhbXOxCIAr", '_blank');

  }

  const navigateToPackagePlan1 = () => {
    // alert(1)
    setShow(false)
    setislogin(false)
    // window.location.href = `${config.baseUrl}packageplan`
    window.open("https://chat.whatsapp.com/Ej1YY6yXsxmKfhbXOxCIAr", '_blank');

  }

  const handleShow = () => {
    setShow(true);
  }

  const connectMetasmaskForAddress = async (e) => {
    e.preventDefault()
    setShow(false)
    try {
      if (window.ethereum) {
        setislogin(true);
        var web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (accounts[0]) {
          let publicAddress = accounts[0];
          const { signature } = await new Promise((resolve, reject) =>
            web3.eth.personal.sign(
              web3.utils.fromUtf8(`Login Mrmint`),
              publicAddress,
              (err, signature) => {
                if (err) return reject(err);
                return resolve({ publicAddress, signature });
              }
            )
          );
          let res = await RegisterAction({ 'address': accounts[0], 'signature': signature });
          if (res.success) {
            setislogin(false);

            toast.success(res.msg);

            Cookies.set('loginSuccessStepMrMint', JSON.stringify(res.data));
            setTimeout(() => {
              // navigate(`${config.baseUrl}dashboard`)
              window.location.href = `${config.baseUrl}dashboard`
            }, 2000);

          } else {
            setislogin(false);
            toast.error(res.msg);
          }
        }
      } else {
        setislogin(false);
        toast.error('Please use Dapp browser!!');
      }
    }
    catch (error) {
      setislogin(false);
      switch (error.code) {
        case 4001:
          toast.error("User Rejected")
          break;
        case -32002:
          toast.error('Request Already Processing, Please check your Meta Mask wallet')
          break;
        case "ERR_NETWORK":
          toast.error("We're experiencing technical difficulties.")
          break;
        default:
          break;
      }
    }
  }

  return (
    <>
      <Header />
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="description" content="Start earning with the best way to make money online today by joining Stepmint. Join millions of people who are living healthier and wealthier lives with this platform." />
        <meta name="title" content="Unlock Wealth and Wellness | Start Earning with Stepmint Today" />
        <meta name="keywords" content="Stepmint, make money online, wealth, wellness, earning online" />
        <meta name="author" content="StepMint" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Toaster />
      <div className="lighttheme bg-white overflow-hidden">
      {/* <section className="newbanner position-relative text-white overflow-hidden" style={{backgroundImage: 'url("assets/images/step_banner_page.png")', backgroundSize:"contain", backgroundRepeat:"no-repeat"}}> */}
      <section className="newbanner" >
        

          
          <div className="banner_bg">
            <img
              src="assets/images/banner-before.png"
              className="bannerbefore"
            />
          </div>
          <Container>
            <Row className="align-items-center">
              <Col lg={6} >
                {/* <Fade left> */}
                  <div className="bannerContent mt-0 " id="onediv">
                    <h1 className="inheritNew">Welcome to the Mega Stepmint App Launch Event!</h1>
                    <p className="mt-4 inheritNew">
                      Mark your calendar for the most anticipated event of the year! Join us.
                    </p>
                    <div className="detailbox">
                    <h6 className="inheritNew"><b>Date:</b> 24th December 2023</h6>
                      <h6 className="inheritNew"><b>Time:</b> 2:00 PM Onwards</h6>
                      <h6 className="inheritNew"><b>Venue:</b> Sheraton Grand Hotel, Indore</h6>
                      <h6 className="inheritNew"><b>Event Fees:</b> Only 1,000 rupees</h6>
                    </div>
                    <Link>
                      <Button onClick={navigateToPackagePlan1} variant="main2" className="text-white px-5 mt-3">
                        Book Your Seat Now
                      </Button>
                    </Link>
                  </div>
                {/* </Fade> */}
              </Col>
              <Col lg={6} className="text-center">
                {/* <Zoom>
                  <div className="bannerImage position-relative">
                    <img src="assets/images/banner_img.png" />
                  </div>
                </Zoom> */}
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="ourmission bg-white pt-0">
          <Container className="bg-blue">
            <Row className="align-items-center p-5">
              <Col lg={6} className=" position-relative">
                <Slide right>
                  <div className="stepmint-icon">
                    <img src="assets/images/stepmint-icon.png" />
                  </div>
                 
                </Slide>
              </Col>
              <Col lg={6} className="">
                <Slide right>
                  <h4 className=" mb-4">
                    <b>OUR MISSION</b>
                  </h4>
                  <p>
                    Stepmint is a revolution meant to inspire individuals from all walks of life to engage in physical activity.
                  </p>
                  <p>
                    Our mission? Well, it revolves around fostering a robust and health-conscious community. We achieve this goal by establishing a revolutionary crypto ecosystem, compelling you to break free from your comfort zone. This will enhance your overall health and grow your wallet simultaneously by blending fitness and wealth-building. It's not just a workout; it's a wealth-building exercise!
                  </p>
                  <Button variant="main2" onClick={navigateToPackagePlan} className="text-white px-5 mt-3">
                    Subscribe
                  </Button>
                </Slide>
              </Col>
            </Row>
          </Container>
        </section> */}

        {/* <section className="weightloss bg-gradient position-relative mt-5">
          <Container>
            <Row>
              <Col lg={7} className="">
                <Zoom>
                  <div className="weight-content text-white pt-4">
                    <div className="mb-3">
                      <h4 className="text-white text-uppercase" style={{ fontWeight: "600" }}>
                        Benefits Of 10,000 Steps Per Day With Stepmint
                      </h4>
              
                    </div>
                    <div className="">
                
                      <div className="list px-0">
                        <Row>
                          <Col lg={12}>
                            <ul className="pl-0">
                              <li>Health Oasis: No sick days ahead!</li>
                              <li>Your ticket to a happy mind.</li>
                              <li>Revitalize your day with a power surge!</li>
                              <li>Strong immunity and better sleep.
                              </li>
                              <li>Your passport to an injury-free, vibrant life.
                              </li>
                              <li>31% lower mortality risk.
                              </li>
                              <li>Stressed? Let every step be your stress vanisher!
                              </li>
                              <li>Shedding weight, one step at a time!</li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Zoom>
              </Col>
              <Col lg={5}>
                <div className="weightloss-img">
                  <img src="assets/images/woman-stretching-leg.png" />
                </div>
              </Col>
            </Row>
          </Container>
          <div className="leftstar">
            <img src="assets/images/leftstar.png" />
          </div>
        </section> */}
        <section className="bg-white sport_community">
          <Container>
    
            <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h2 className="mb-4"><b>What's Included</b></h2>
                    <div className="mb-2">
                      <h4>
                        Dinner with influential personalities:
                      </h4>
                      <p className="mb-0">
                        Indulge in a delightful dining experience with a sumptuous dinner.
                      </p>
                    </div>
                    <div className="mb-2">
                      <h4>
                      App Launch Event with High Tea:
                      </h4>
                      <p className="mb-0">
                      Experience India’s biggest fitness app launch along with a delightful High Tea experience, surrounded by the luxury of Sheraton.
                      </p>
                    </div>
                    <div className="mb-2">
                      <h4>
                      Free Entry to Walkathon (Get Free Sports T-shirt):
                      </h4>
                      <p className="mb-0">
                      Participate in the Walkathon on 25th December, starting near Bombay Hospital, with free entry, and receive an exclusive Stepmint sports T-shirt.

                      </p>
                    </div>

                    <div className="mb-2">
                      <h4>
                      Best Business Insights:
                      </h4>
                      <p className="mb-0">
                      Engage in profound discussions and gain valuable insights from some of India's top business leaders.
                      </p>
                    </div>
                  </div>
                </Zoom>
              </Col>
              <Col lg={6} className="text-right">
                <Fade right>
                  <div className="includebox">
                    <img src="assets/images/include_back1.jpg" className="include_back1" />
                  </div>
                </Fade>
              </Col>
            </Row>
            <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="text-left">
                <Fade right>
                  <img src="assets/images/include_back2.jpg" className="include_back2" />
                </Fade>
              </Col>
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h2 className="mb-4"><b>Opportunities Await</b></h2>
                    <div className="mb-2">
                      <h4>
                        Meet Business Leaders
                      </h4>
                      <p className="mb-0">
                        Get the chance to listen to insights from some of India's top business leaders.
                      </p>
                    </div>
                    <div className="mb-2">
                      <h4>
                        Expand your network
                      </h4>
                      <p className="mb-0">
                        Create valuable connections and expand your professional network.
                      </p>
                    </div>
                    <div className="mb-2">
                      <h4>
                       Learn, Share, Explore:
                      </h4>
                      <p className="mb-0">
                        Engage in a platform where ideas are shared, knowledge is gained, and new horizons are explored.
                      </p>
                    </div>
                  </div>
                </Zoom>
              </Col>
            </Row>
            {/* <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4>
                      <b>Health with Wealth</b>
                    </h4>
                    <p className="">
                      Wealth opens the doors to a comfy life, education, and personal growth. It's the ticket to security, travel, and pursuing passions. Health, the bedrock of a fulfilling life, lets you relish every moment, chase goals, and build meaningful connections. Striking a balance is a must. Focusing solely on wealth invites stress; neglecting health means limited access to care and comfort. Prioritize health with exercise and wellness. Balancing both is the safe spot, where health enriches wealth benefits, and wealth fuels a healthy lifestyle. It's the ideal path to a vibrant life! With Stepmint you follow this ideal path to a grand lifestyle.
                    </p>
                    
                  </div>
                </Zoom>
              </Col>
              <Col lg={6} className="text-right">
                <Fade right>
                  <img src="assets/images/health_versus.png" />
                </Fade>
              </Col>
            </Row> */}
          </Container>
        </section>
        <section className="getstarted bg-white p-0 pb-5">
          <Container className="bg-blue position-relative">
            <Row className="align-items-center">
              <Col lg={6} className="">
                <Slide right>
                  <div className="p-3">
                    <h2>Join us</h2>
                    <h4 className="pt-2 pb-2">
                      <b>as we usher in a new era with the Stepmint App Launch Event!
                      </b>
                    </h4>
                    <Button variant="main2" onClick={navigateToPackagePlan} className="text-white px-5 md-md-3">
                      Reserve Your Spot Now
                    </Button>
                  </div>
                </Slide>
              </Col>
              <Col lg={6} className=" text-center">
                <div className="sport-girl">
                  <img src="assets/images/sportgirl.png" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>


      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="Nftmodal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="btn-rounded pt-2 pb-3">
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-3">
                <Row className="align-items-center">
                  <Col lg={12} className="mt-0">
                    <button
                      className="btn btn-main2 text-white btn-rounded px-5 rounded-5"
                      onClick={connectMetasmaskForAddress}
                    >
                      Connect Metamask
                    </button>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <span
                      className=" btn-rounded px-5 rounded-5"
                    // onClick={connectMetasmaskForAddress}
                    >
                      or
                    </span>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button
                      className="btn btn-main2 btn-sm text-white btn-rounded px-5 rounded-5"
                      onClick={navigateToSignup}
                    >
                      Signup
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};
export default Home;
