import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import { Button, Container, Dropdown, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import ListMenuButton from "./listMenu";
import { useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";

const Dashboardheader = () => {
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
        Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
      );
  const [lastparturl, setlastparturl] = useState([]);
  useEffect(() => {
    dynamicLink();

    if (!loginData.id) {
      window.location.href = `${config.baseUrl}`;
    }
  }, []);

  const dynamicLink = () => {
    let url = window.location.href;
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];
    const secondLastPart = parts[parts.length - 2];
console.log(secondLastPart);

    if (secondLastPart === "nftdetail") {
      setlastparturl("nftdetail"); // Set this as a "base" for dynamic URLs
    } else {
      setlastparturl(lastPart);
    }
  };
  const { pathname } = useLocation();

  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDrag = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll speed
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const stopDrag = () => {
    setIsDragging(false);
  };


  // Check if the content overflows
  useEffect(() => {
    const checkOverflow = () => {
      if (scrollContainerRef.current) {
        const isContentOverflowing =
          scrollContainerRef.current.scrollWidth >
          scrollContainerRef.current.clientWidth;
        setIsOverflow(isContentOverflowing);
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  const activeTabRef = useRef(null);

const [isOverflow, setIsOverflow] = useState(false);

// Check if content overflows
useEffect(() => {
  const checkOverflow = () => {
    if (scrollContainerRef.current) {
      const isContentOverflowing =
        scrollContainerRef.current.scrollWidth >
        scrollContainerRef.current.clientWidth;
      setIsOverflow(isContentOverflowing);
    }
  };
  checkOverflow();
  window.addEventListener("resize", checkOverflow);
  return () => window.removeEventListener("resize", checkOverflow);
}, []);

// Center the active tab on load
useEffect(() => {
  if (activeTabRef.current && scrollContainerRef.current) {
    scrollToCenter(activeTabRef.current);
  }
}, [pathname]);

const scrollToCenter = (tabElement) => {
  const container = scrollContainerRef.current;
  if (!tabElement || !container) return;

  const containerWidth = container.clientWidth;
  const tabLeft = tabElement.offsetLeft;
  const tabWidth = tabElement.clientWidth;

  // Scroll so that the active tab is centered
  const scrollPosition = tabLeft - (containerWidth / 2 - tabWidth / 2);
  container.scrollTo({ left: scrollPosition, behavior: "smooth" });
};

const scrollLeftFunc = () => {
  scrollContainerRef.current.scrollBy({ left: -100, behavior: "smooth" });
};

const scrollRightFunc = () => {
  scrollContainerRef.current.scrollBy({ left: 100, behavior: "smooth" });
};

  const scrollToActiveTab = (event) => {
    const tab = event.target.closest(".scrl");
    if (tab && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const tabCenter =
        tab.offsetLeft + tab.offsetWidth / 2 - container.clientWidth / 2;
      container.scrollTo({ left: tabCenter, behavior: "smooth" });
    }
  };


const checkOverflow = () => {
  const container = scrollContainerRef.current;
  if (container) {
    // Check if content overflows
    setIsOverflow(container.scrollWidth > container.clientWidth);
  }
};
  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);
  return (
    <>
      <div className="tabs">
      <Container>
  <div className="position-relative mt-2">
    {isOverflow && (
      <Button
        className="scroll-arrow left-arrow h-auto rounded-5 dashboard-left"
        style={{ backgroundColor: "transparent", color: "white" }}
        onClick={scrollLeftFunc}
      >
        <FaChevronLeft />
      </Button>
    )}

    <div className="scroll-container" ref={scrollContainerRef}>
      <div className="scroll-button">
        <div
          className={`scrl ${pathname === "/dashboard" ? "active" : ""}`}
          ref={pathname === "/dashboard" ? activeTabRef : null}
        >
          <Link
            to={`${config.baseUrl}dashboard`}
            className={`${
              pathname === "/dashboard" ? "active" : ""
            } custom-link`}
          >
            Dashboard
          </Link>
        </div>
      </div>

      <div className="scroll-button">
        <div
          className={`scrl ${pathname === "/packageplan" ? "active" : ""}`}
          ref={pathname === "/packageplan" ? activeTabRef : null}
        >
          {/* <Link
            to={`${config.baseUrl}packageplan`}
            className={`${
              pathname === "/packageplan" ? "active" : ""
            } custom-link`}
          > */}
            <Link
                to={`${config.baseUrl}packageplan`}
                className={`${lastparturl === "packageplan" ? "active" : lastparturl == "nftdetail"
                  ? "active" : ""
                  } custom-link`}
              >
            Subscriptions
          </Link>
        </div>
      </div>

      <div className="scroll-button">
        <div
          className={`scrl ${pathname === "/useractivity" ? "active" : ""}`}
          ref={pathname === "/useractivity" ? activeTabRef : null}
        >
          <Link
            to={`${config.baseUrl}useractivity`}
            className={`${
              pathname === "/useractivity" ? "active" : ""
            } custom-link`}
          >
            User Activity
          </Link>
        </div>
      </div>

      <div className="scroll-button">
        <div
          className={`scrl ${pathname === "/referral" ? "active" : ""}`}
          ref={pathname === "/referral" ? activeTabRef : null}
        >
          <Link
            to={`${config.baseUrl}referral`}
            className={`${
              pathname === "/referral" ? "active" : ""
            } custom-link`}
          >
            Referral Reward
          </Link>
        </div>
      </div>

      <div className="scroll-button">
        <div
          className={`scrl ${pathname === "/matchingbonus" ? "active" : ""}`}
          ref={pathname === "/matchingbonus" ? activeTabRef : null}
        >
          <Link
            to={`${config.baseUrl}matchingbonus`}
            className={`${
              pathname === "/matchingbonus" ? "active" : ""
            } custom-link`}
          >
            Community Reward
          </Link>
        </div>
      </div>

      <div className="scroll-button">
        <div
          className={`scrl ${
            ["/wallethistory", "/deposit", "/withdraw"].includes(pathname)
              ? "active"
              : ""
          }`}
          ref={
            ["/wallethistory", "/deposit", "/withdraw"].includes(pathname)
              ? activeTabRef
              : null
          }
        >
          <Link
            to={`${config.baseUrl}wallethistory`}
            className={`${
              ["/wallethistory", "/deposit", "/withdraw"].includes(pathname)
                ? "active"
                : ""
            } custom-link`}
          >
            Wallet
          </Link>
        </div>
      </div>

      <div className="scroll-button" style={{ paddingTop: 7 }}>
        <div
          className={`scrl ${
            pathname === "/ldp1" || pathname === "/ldp2" ? "active" : ""
          }`}
          style={{ marginTop: 0, marginLeft: -5 }}
          ref={
            pathname === "/ldp1" || pathname === "/ldp2" ? activeTabRef : null
          }
        >
          <Dropdown>
            <Dropdown.Toggle
              style={{ backgroundColor: "transparent", border: "none" }}
              id="dropdown-basic"
            >
              <Link
                className={`${
                  ["ldp1", "ldp2"].includes(lastparturl)
                    ? "active text-decoration-none"
                    : "custom-link text-decoration-none px-2"
                }`}
              >
                Events
              </Link>
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-0">
              <Dropdown.Item className="sbm-l">
                <Link
                  to={`${config.baseUrl}ldp1`}
                  className="submenu-link text-dark"
                >
                  LDP 1
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="sbm-l">
                <Link
                  to={`${config.baseUrl}ldp2`}
                  className="submenu-link text-dark"
                >
                  LDP 2
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>

    {isOverflow && (
      <Button
        className="scroll-arrow right-arrow h-auto rounded-5 dashboard-left"
        style={{ backgroundColor: "transparent", color: "white" }}
        onClick={scrollRightFunc}
      >
        <FaChevronRight />
      </Button>
    )}
  </div>
</Container>


        {/* <Container>
          <Nav
            variant="pills"
            className="justify-content-sm-between justify-content-center nav nav-pills"
            activeKey="Stepmint/dashboard"
          >
            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}dashboard`}
                className={`${lastparturl == "dashboard" ? "active" : ""
                  } custom-link`}
              >
                Dashboard
              </Link>
            </Nav.Item>
            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}packageplan`}
                className={`${lastparturl == "packageplan" ? "active" : lastparturl === "nftdetail"
                  ? "active" : ""
                  } custom-link`}
              >
                Subscriptions
              </Link>
            </Nav.Item>

            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}useractivity`}
                className={`${lastparturl == "useractivity" ? "active" : ""
                  } custom-link`}
              >
                User Activity
              </Link>
            </Nav.Item>

            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}referral`}
                className={`${lastparturl == "referral" ? "active" : ""
                  } custom-link`}
              >
                Referral
              </Link>
            </Nav.Item>

            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}matchingbonus`}
                className={`${lastparturl == "matchingbonus" ? "active" : ""
                  } custom-link`}
              >
                Community Rewards
              </Link>
            </Nav.Item>

            <Nav.Item className="custom-nl">
              <Link
                to={`${config.baseUrl}wallethistory`}
                className={`${lastparturl == "wallethistory"
                  ? "active "
                  : lastparturl == "deposit"
                    ? "active "
                    : lastparturl == "withdraw"
                      ? "active "
                      : ""
                  } custom-link`}
              >
                Wallet
              </Link>
            </Nav.Item>

            <Nav.Item className="nav-item-with-submenu custom-nl">
              <Link
                className={`${lastparturl === "ldp2" || lastparturl === "ldp1"
                  ? "active"
                  : ""
                  } custom-link`}
              >
                Events
              </Link>
              <div className="submenu">
                <Nav className="submenu-items d-flex flex-column gap-1 ">
                  <Nav.Item className="submenu-item">
                    <Link to={`${config.baseUrl}ldp1`} className="submenu-link">
                      LDP 1
                    </Link>
                  </Nav.Item>
                  <Nav.Item className="submenu-item">
                    <Link to={`${config.baseUrl}ldp2`} className="submenu-link">
                      LDP 2
                    </Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Nav.Item>
          </Nav>
        </Container> */}
      </div>
    </>
  );
};
export default Dashboardheader;
