export const apiConstants = {
    
    USER_LOGIN: 'weblogin',

    USER_REGISTRATER: 'userRegister',
    PHONE_OTP_VERIFICATION:'verifyPhoneRegistrationOtp',
    GENRATE_PHONE_VERIFICATION_OTP:'generatePhoneRegistrationOtp',
    FORGOT_PASSWORD:'forgotPassword',
    RESET_PASSWORD:'resetPassword',
    EMAIL_OTP_VERIFICATION:'generateEmailRegistrationOtp',
    GENRATE_Email_VERIFICATION_OTP:'verifyEmailRegistrationOtp',

    

}