import React from 'react';
import { Helmet } from 'react-helmet';

const HowItWorksMetaTag = () => {
  return (
    <Helmet>
       <meta charset="UTF-8" />
       <meta name="description" content="StepMint.io simplifies earning rewards for walking by guiding users through Web3 applications, allowing for seamless participation and easy tracking of rewards earned." />
        <meta name="title" content="rewards for walking |walking pay app|walk to get money" />
        <meta name="keywords" content="rewards for walking, rewards for walking, walking pay app, walk to get money, walk and earn money app" />
        <title>rewards for walking |walking pay app|walk to get money </title>
      {/* Organization JSON-LD */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "StepMint",
          "alternateName": "StepMint",
          "url": "https://stepmint.io/",
          "logo": "https://stepmint.io/assets/images/logo.png",
          "sameAs": [
            "https://www.facebook.com/profile.php?id=61551542573562",
            "https://twitter.com/step_mint",
            "https://instagram.com/stepmint?igshid=MzMyNGUyNmU2YQ==",
            "https://www.linkedin.com/company/stepmint/about/?viewAsMember=true"
          ]
        }
        `}
      </script>

      {/* Website JSON-LD */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "StepMint",
          "url": "https://stepmint.io/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "{search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }
        `}
      </script>

      {/* Meta Tags */}
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content="rewards for walking |walking pay app|walk to get money" />
      <meta property="og:url" content="https://stepmint.io/howitwork" />
      <meta property="og:image" content="https://stepmint.io/assets/images/how_shoes.png" />
      <meta property="og:description" content="StepMint.io simplifies earning rewards for walking by guiding users through Web3 applications, allowing for seamless participation and easy tracking of rewards earned." />
      <meta property="business:contact_data:street_address" content="322, Magneto Offizo, Magneto Mall" />
      <meta property="business:contact_data:locality" content="raipur" />
      <meta property="business:contact_data:region" content="Chhattisgarh" />
      <meta property="business:contact_data:postal_code" content="492001" />
      <meta property="business:contact_data:country_name" content="India" />

      {/* Website Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="rewards for walking |walking pay app|walk to get money" />
      <meta property="og:url" content="https://stepmint.io/howitwork" />
      <meta property="og:image" content="https://stepmint.io/assets/images/how_shoes.png" />
      <meta property="og:description" content="StepMint.io simplifies earning rewards for walking by guiding users through Web3 applications, allowing for seamless participation and easy tracking of rewards earned." />
    </Helmet>
  );
};

export default HowItWorksMetaTag;
