import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Dashboardheader from "./directives/dashboardheader";
import Footer from "./directives/footer";
import Dashboardsubheader from "./directives/dashboardsubheader";
import config from "./config/config";
import Cookies from "js-cookie";


const DashboardHeaderLayout = () => {
  const navigate = useNavigate();

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
        Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
      );
  useEffect(() => {
    if (!loginData.id) {
      navigate(`${config.baseUrl}`);
    }
  }, []);
  return (
    <div className="darktheme">
      <Dashboardsubheader />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default DashboardHeaderLayout;
