import React, { useEffect, useState } from "react";
import Footer from "../directives/footer";
import { Container, Row, Col, Form } from "react-bootstrap";
import Tree from "react-d3-tree";
import "../custom-tree.css";

import Dashboardheader from "../directives/dashboardheader";

const Referraltree = () => {
  const orgChart = {
    name: "CEO",
    children: [
      {
        name: "Manager",
        attributes: {
          department: "Production",
        },
        children: [
          {
            name: "Foreman",
            attributes: {
              department: "Fabrication",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
          {
            name: "Foreman",
            attributes: {
              department: "Assembly",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
        ],
      },
    ],
  };
  return (
    <>
      <Dashboardheader />
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          {/* ---------- */}
          <div className=" mb-5">
            <Container className="">
              <div className="staking_banner card-view mh-auto pt-3 pb-3 p-5">
                <img src="assets/images/star_left.png" className="star_left" />
                <img
                  src="assets/images/star_right.png"
                  className="star_right"
                />
                <Row className="align-items-center">
                  <Col lg={6}>
                    <Form.Group
                      controlId="formBasicEmail"
                      className="w-60 mx-auto"
                    >
                      <Form.Label className="mb-0 pull-left mb-1">
                        Search ID
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder=" Enter ID"
                        className="mr-3 bg-white text-black form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <img
                      src="assets/images/referralearn.png"
                      className="stake_img w-100"
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          {/* ---------- */}
          <div className=" mb-5 ">
            <Container className="">
              <div id="treeWrapper" style={{ width: "100%", height: "50em" }}>
                <Tree
                  data={orgChart}
                  transitionDuration="1000"
                  pathFunc="step"
                  rootNodeClassName="node__root"
                  branchNodeClassName="node__branch"
                  leafNodeClassName="node__leaf"
                  orientation="vertical"
                />
              </div>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Referraltree;
