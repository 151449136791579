import React from 'react';
import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap';

const CongratulationModal = ({ showCongratulationModal, handleOpenLogin }) => {
  return (
    <Modal show={showCongratulationModal} size="lg"  centered>
      
      <Modal.Body className='py-4'>
        <Row className="text-center">
          <Col className=''>
            <img src="assets/images/check-mark.png" width={120} className='mb-4' alt="" />
            <h2 className='fw-bold'>Congratulations!</h2>
            <p>You've Earned a Free Subscription! </p>
            {/* {loading && <Spinner animation="border" variant="success" />} */}
          </Col>
        </Row>
        <Row>
          <div className="field-set mb-2 text-center ">
            <Button
              type="submit"
              variant="main2"
              className=" px-4 text-white"
              onClick={handleOpenLogin}

            >
              <span className="icon"> </span> Go to Login
            </Button>

          </div>
        </Row>
      </Modal.Body>

    </Modal>
  );
};

export default CongratulationModal;
