import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import config from "../config/config";
import { useNavigate, Outlet } from "react-router-dom";

export default function ProtectedRoutes() {
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();
  const loginData = Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" });
  const isAuth = loginData && JSON.parse(loginData).id;
  useEffect(() => {
    const loginData = Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" });
    if (!loginData || !JSON.parse(loginData).id) {
      navigate(`${config.baseUrl}`);
    }
    else {
      setLoading(false); // Once authenticated, stop loading
    }
   
  }, [navigate]);

  if (loading) {
    return null; 
  }



  return isAuth ? <Outlet /> : null;
}
