import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import {
  stepRewardHistoryAction,
  userWeeklyStatisticsAction,
  userMonthlyStatisticsAction,
  userPerDayStatisticsAction,
} from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import Dashboardheader from "../directives/dashboardheader";

const StepDashboard = () => {
  const [userWeeklyStatistics, setuserWeeklyStatistics] = useState([]);
  const [userMonthlyStatistics, setuserMonthlyStatistics] = useState([]);
  const [steprewardhistory, setsteprewardhistory] = useState([]);
  const [userWeeklyStatisticsData, setuserWeeklyStatisticsData] = useState({});
  const [userMonthlyStatisticsData, setuserMonthlyStatisticsData] = useState(
    {}
  );
  const [userStat, setUserStat] = useState([]);

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const [totalSteps, settotalSteps] = useState(0);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };

  const totalPages = Math.ceil(steprewardhistory.length / itemsPerPage);
  const totalPagesStat = Math.ceil(userStat.length / itemsPerPage);

  const isOnFirstPage = currentPage == 0;
  const isOnLastPage = currentPage == totalPages - 1;
  const isOnLastPageStat = currentPage == totalPages - 1;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const steprewardhistorySliced = steprewardhistory.slice(startIndex, endIndex);
  const userStatSlice = userStat.slice(startIndex, endIndex);

  useEffect(() => {
    ReferralFunc();
  }, []);

  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  const ReferralFunc = async () => {
    let res = await userWeeklyStatisticsAction();
    let res1 = await userMonthlyStatisticsAction();
    let res2 = await stepRewardHistoryAction();
    let res3 = await userPerDayStatisticsAction();

    if (res.success) {
      setuserWeeklyStatistics(res.data.week);
      setuserWeeklyStatisticsData(res.data.Statistics);

      console.log("setuserWeeklyStatistics", res.data);
    } else {
    }

    if (res1.success) {
      setuserMonthlyStatistics(res1.data.month);
      setuserMonthlyStatisticsData(res1.data.Statistics);
      console.log("setuserMonthlyStatistics", res1.data);
    } else {
    }
    if (res2.success) {
      setsteprewardhistory(res2.data);
      console.log("setsteprewardhistory", res2.data);
      let totalAmount = 0;
      for (let i = 0; i < res2.data.length; i++) {
        let amount = parseFloat(res2.data[i]?.amount);
        totalAmount += amount;
      }
      console.log("totalAmount", totalAmount);
      settotalSteps(totalAmount);
    } else {
    }

    if (res3.success) {
      setUserStat(res3.data);
      console.log("setUserStat", res3.data);
    } else {
    }
  };
  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          {/* -------------------- */}

          <div className=" mb-5">
            <div className="container referral">
              <div className="mr-referral-content">
                <div className="mr-referral-block">
                  <Row className="gy-3">
                    <Col lg={6} md={12} className="">
                      <div className="mb-3">
                        <Container className="">
                          <Row>
                            <Col lg={12} className="p-0">
                              {/* <h1 className="text-white">User Activity </h1> */}
                              <div className="d-flex mb-2">
                                <h4 className="text-white text-bold ">
                                  Step Reward Earned:-
                                </h4>
                                &emsp;
                                <h4 className="text-white text-bold">
                                  {totalSteps == "" ||
                                  totalSteps == undefined ||
                                  totalSteps == 0 ||
                                  totalSteps == null
                                    ? 0
                                    : parseFloat(totalSteps).toFixed(2)}
                                  &nbsp;MNT
                                </h4>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="weeklyrewarddata"
                      >
                        <Nav variant="pills" className="btn-sm mb-4 tabs_btn ">
                          <Row style={{width:"100vw"}}>
                            <Col lg={6} sm={6} >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="weeklyrewarddata"
                                  className="card-view1 text-center customnav-link"
                                >
                                  Weekly Steps Statistics
                                </Nav.Link>
                              </Nav.Item>
                            </Col>
                            <Col lg={6} sm={6}>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="monthlyrewarddata"
                                  className="card-view1 text-center text-nowrap customnav-link"
                                >
                                  Monthly Steps Statistics
                                </Nav.Link>
                              </Nav.Item>
                            </Col>
                          </Row>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="weeklyrewarddata">
                            <div
                              className="mr-referral-carditem mr-referralref-item buyform card-view1 mt-4 mb-4"
                              style={{ minHeight: "288px" }}
                            >
                              <div className="mr-referral-inner">
                                <div className="mr-contant-block">
                                  <h4>
                                    <Row>
                                      <Col>Day With Max Step Count</Col>
                                      <Col>
                                        <span className="seprator"> : </span>
                                        <span className="text">
                                          {
                                            userWeeklyStatisticsData.dayWithMaxSteps
                                          }
                                        </span>
                                      </Col>
                                    </Row>
                                  </h4>
                                  <h4>
                                    <Row>
                                      <Col>Time</Col>
                                      <Col>
                                        <span className="seprator"> : </span>
                                        <span className="text">
                                          {userWeeklyStatisticsData.in_minutes}
                                          &nbsp;min
                                        </span>
                                      </Col>
                                    </Row>
                                  </h4>
                                  <h4>
                                    <Row>
                                      <Col>Max Step Count</Col>
                                      <Col>
                                        <span className="seprator"> : </span>
                                        <span className="text text-white">
                                          {userWeeklyStatisticsData.maxSteps}
                                        </span>
                                      </Col>
                                    </Row>
                                  </h4>
                                  <h4 className="mb-0">
                                    <Row>
                                      <Col>Daily Goal</Col>
                                      <Col className="d-flex">
                                        <div className="seprator"> : </div>
                                        <div className="text text-white">
                                          {userWeeklyStatisticsData.total_goal}
                                          &nbsp; steps per day
                                        </div>
                                      </Col>
                                    </Row>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="monthlyrewarddata">
                            <div
                              className="mr-referral-carditem mr-referralref-item buyform card-view1 my-4"
                              style={{ minHeight: "348px" }}
                            >
                              <div className="mr-referral-inner">
                                <div className="mr-contant-block">
                                  <h4>
                                  <Row>
                                      <Col> Month With Max Steps</Col>
                                      <Col className="d-flex">
                                        <div className="seprator"> : </div>
                                        <div className="text text-white">
                                          {userMonthlyStatisticsData.dayWithMaxSteps}
                                        </div>
                                      </Col>
                                    </Row>
                                   
                                  
                                  </h4>
                                  <h4>

                                    <Row>
                                      <Col> Time</Col>
                                      <Col className="d-flex">
                                      <span className="seprator"> : </span>
                                    <span className="text">
                                      {userMonthlyStatisticsData.in_minutes}
                                      &nbsp;min
                                    </span>
                                      </Col>
                                    </Row>
                                  </h4>
                                  <h4>
                                    
                                    

                                    <Row>
                                      <Col> Max Steps</Col>
                                      <Col className="d-flex">
                                      <span className="seprator"> : </span>
                                    <span className="text text-white">
                                      {userMonthlyStatisticsData.maxSteps}
                                    </span>
                                      </Col>
                                    </Row>
                                  </h4>
                                  <h4 className="mb-0">
                                  
                                   

                                    <Row>
                                      <Col>   Daily Goal</Col>
                                      <Col className="d-flex">
                                      <span className="seprator"> : </span>
                                    <span className="text text-white">
                                      {userMonthlyStatisticsData.total_goal}
                                      &nbsp; steps per day
                                    </span>
                                      </Col>
                                    </Row>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Col>
                    <Col lg={6} md={12} className=" ">
                      <div className="mr-referral-carditem mr-referral-item buyform card-view1 px-4">
                        <div className="mr-contant-block">
                          <h4>User Activity</h4>
                          <img
                            src="assets/images/unser_activity.png"
                            className="user_girl"
                            width="100%"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="latest_transaction mb-5">
            <Container>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="weeklyreward"
              >
                <Row>
                  <Col lg={12}>
                    <Nav variant="pills" className="">
                      <Row style={{width:"100vw"}}>
                        <Col lg={3}>
                          <Nav.Link
                            eventKey="weeklyreward"
                            className="card-view1 text-nowrap text-center customnav-link"
                            onClick={CurrentPageResetFunc}
                          >
                            Weekly Step Count
                          </Nav.Link>
                        </Col>
                        <Col lg={3}>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="monthlyreward"
                              className="card-view1 text-center customnav-link text-nowrap"
                              onClick={CurrentPageResetFunc}
                            >
                              Monthly Step Count
                            </Nav.Link>
                          </Nav.Item>
                        </Col>
                        <Col lg={3}>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="userstat"
                              className="card-view1 text-center customnav-link"
                              onClick={CurrentPageResetFunc}
                            >
                              Statistics
                            </Nav.Link>
                          </Nav.Item>
                        </Col>
                        <Col lg={3}>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="stepreward"
                              className="card-view1 text-center customnav-link"
                              onClick={CurrentPageResetFunc}
                            >
                              Step Reward
                            </Nav.Link>
                          </Nav.Item>
                        </Col>
                      </Row>
                    </Nav>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <Tab.Content>
                      <Tab.Pane eventKey="weeklyreward">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th>S.No</th>
                                <th>Day</th>
                                <th>Step Count</th>
                                <th>Total Duration (in mins)</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {userWeeklyStatistics.length.length == 0 ? (
                                <tr className="tr_list">
                                  <td colspan="4">No Team Available</td>
                                </tr>
                              ) : (
                                userWeeklyStatistics.map((item, key) => (
                                  <tr className="tr_list">
                                    <td style={{width:100}}>{key + 1}</td>
                                    <td>{item.day}</td>
                                    <td>{item.total_steps}</td>
                                    <td>{item.total_duration}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="monthlyreward">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th style={{width:100}}>S.No</th>
                                <th>Months</th>
                                <th>Step Count</th>
                                <th>Total Duration (in mins)</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {userMonthlyStatistics.length == 0 ? (
                                <tr className="tr_list">
                                  <td colspan="6"> No Data Available</td>
                                </tr>
                              ) : (
                                userMonthlyStatistics.map((item, key) => (
                                  <tr className="tr_list">
                                    <td style={{width:100}}>{key + 1}</td>
                                    <td>{item.month}</td>
                                    <td>{item.total_steps}</td>
                                    <td>{item.total_duration}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="userstat">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th>S.No</th>
                                <th>Start Time</th>
                                <th>Stop Time</th>
                                <th>Step Count</th>
                                <th>Reward</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {userStat.length == 0 ? (
                                <tr className="tr_list">
                                  <td colspan="5">No History Available</td>
                                </tr>
                              ) : (
                                userStatSlice.map((item, key) => (
                                  <tr className="tr_list">
                                    <td style={{width:100}}>
                                      {key + 1 + currentPage * itemsPerPage}
                                    </td>
                                    <td>{item.start}</td>
                                    <td>{item.stop}</td>
                                    <td>{item.steps}</td>
                                    <td>{item.isclaim}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {userStat.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPageStat ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPageStat ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  userStat.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="stepreward">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th>S.No</th>
                                <th>Date</th>
                                <th>Nft ID</th>
                                <th>MNT Reward</th>
                                <th>Step Count</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {console.log(steprewardhistory.length)}
                              {steprewardhistory.length == 0 ? (
                                <tr className="tr_list">
                                  <td colspan="5">No History Available</td>
                                </tr>
                              ) : (
                                steprewardhistorySliced.map((item, key) => (
                                  <tr className="tr_list">
                                    <td style={{width:100}}>
                                      {key + 1 + currentPage * itemsPerPage}
                                    </td>
                                    <td>{item.datetime}</td>
                                    <td>{item.uniqueId}</td>
                                    <td>{item.amount} MNT</td>
                                    <td>{item.steps}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {steprewardhistory.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                // previousLabel={"Previous"}
                                // nextLabel={"Next"}
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPage ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPage ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  steprewardhistory.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default StepDashboard;
