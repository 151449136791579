import React from 'react';
import { Helmet } from 'react-helmet';

const HomeMetaTag = () => {
  return (
    <Helmet>
        <meta charset="UTF-8" />
        <meta name="google-site-verification" content="kB1Ejpt6XDiAOwsPrwN-z_WcR8-W-vEiXj88zsOgRBA" />
        <meta name="description" content="StepMint.io empowers users to get paid to walk through engagement with Web3 applications, combining simplicity with robust earning potential." />
        <meta name="title" content="Walk & Earn Rewards|Walk to Earn|Get Paid to Walk|Stepmint" />
        <meta name="keywords" content="Walk & Earn Rewards, Walk to Earn, Get Paid to Walk, Stepmint, Apps That Pay You to Walk, Walk and Earn Apps, earn money from walking" />
        <title>Walk & Earn Rewards|Walk to Earn|Get Paid to Walk|Stepmint</title>
      {/* JSON-LD for Organization */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "StepMint",
          "alternateName": "StepMint",
          "url": "https://stepmint.io/",
          "logo": "https://stepmint.io/assets/images/logo.png",
          "sameAs": [
            "https://www.facebook.com/profile.php?id=61551542573562",
            "https://twitter.com/step_mint",
            "https://instagram.com/stepmint?igshid=MzMyNGUyNmU2YQ==",
            "https://www.linkedin.com/company/stepmint/about/?viewAsMember=true"
          ]
        }
        `}
      </script>

      {/* JSON-LD for Website */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "StepMint",
          "url": "https://stepmint.io/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "{search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }
        `}
      </script>

      {/* JSON-LD for FAQ Page */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "I encountered technical trouble. What do I have to do?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "If you stumble upon any technical troubles or bugs, please write us at info@stepmint.io"
              }
          },{
            "@type": "Question",
            "name": "Do you have a referral Program?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, we have!"
            }
          },{
            "@type": "Question",
            "name": "Can I cancel my premium subscription?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We have a No cancellation policy."
            }
          },{
            "@type": "Question",
            "name": "Is my data safe with StepMint.io?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, we take the security of your data seriously. StepMint.io uses industry-leading security measures to protect your information."
            }
          },{
            "@type": "Question",
            "name": "How can I contact customer support?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "If you have any questions or need assistance, you can raise tickets. Our customer support team will get back to you."
            }
          },{
            "@type": "Question",
            "name": "What payment methods do you accept for premium plans?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We accept MNT only. Our payment processing is secure, and your information is always safe."
            }
          },{
            "@type": "Question",
            "name": "Is the StepMint App free to use?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "No."
            }
          },{
            "@type": "Question",
            "name": "How do I get started?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "To get started, simply visit our website at https://stepmint.io/ and subscribe now for early bird access!"
            }
          },{
            "@type": "Question",
            "name": "What is StepMint.io?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Stepmint is a revolutionary platform that transforms your daily steps into real crypto rewards, merging fitness and finance seamlessly using Web 3.0 technology."
            }
          }]
        }
        `}
      </script>

      {/* Open Graph Meta Tags */}
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content="Walk & Earn Rewards | Walk to Earn | Get Paid to Walk | Stepmint" />
      <meta property="og:url" content="https://stepmint.io/" />
      <meta property="og:image" content="https://stepmint.io/assets/images/banner_img.png" />
      <meta property="og:description" content="StepMint.io empowers users to get paid to walk through engagement with Web3 applications, combining simplicity with robust earning potential." />
      <meta property="business:contact_data:street_address" content="322, Magneto Offizo, Magneto Mall" />
      <meta property="business:contact_data:locality" content="Raipur" />
      <meta property="business:contact_data:region" content="Chhattisgarh" />
      <meta property="business:contact_data:postal_code" content="492001" />
      <meta property="business:contact_data:country_name" content="India" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content="Walk & Earn Rewards | Walk to Earn | Get Paid to Walk | Stepmint" />
      <meta property="og:url" content="https://stepmint.io/" />
      <meta property="og:image" content="https://stepmint.io/assets/images/banner_img.png" />
      <meta property="og:description" content="StepMint.io empowers users to get paid to walk through engagement with Web3 applications, combining simplicity with robust earning potential." />
    </Helmet>
  );
};

export default HomeMetaTag;
