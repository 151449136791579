import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { IoLogoAndroid } from "react-icons/io";
import { FaApple } from "react-icons/fa";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Modal,
  Accordion,
  Image
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "../directives/header.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../component/home.css";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { RegisterAction } from "../Action/user.action";
import Web3 from "web3";
import { Helmet } from "react-helmet";
import Faqnew from "./HomeComponent/Home/faq";
import { getFaqsAction } from "../Action/user.action";
import HomeMetaTag from "./MetaTags/HomeMetaTag";
import Swal from "sweetalert2";

const Home = () => {
  const [show, setShow] = useState(true);
  const [islogin, setislogin] = useState(false);
  const [faqsList, setFaqsList] = useState([]);
  const navigate = useNavigate();
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );
  console.log(loginData);
  useEffect(() => {
    getFaqs();
  }, [])

  const getFaqs = async () => {
    let res = await getFaqsAction();
    if (res.success) {
      setFaqsList(res.data);
    } else {
      setFaqsList([]);
    }
  }


  const navigateToSignup = () => {
    setShow(false);
    setislogin(false);
    navigate(`${config.baseUrl}signup`);
  };

  const handleClose = () => {
    setShow(false);
  };
  const navigateToPackagePlan = (e) => {
    e.preventDefault()

    navigate(`${config.baseUrl}packageplan`);
    setShow(false);
    setislogin(false);
    window.scrollTo(0, 0); // Scroll to the top of the page

  };

  const navigateToPackagePlan1 = () => {
    // alert(1)
    setShow(false);
    setislogin(false);
    window.location.href = `${config.baseUrl}packageplan`;
  };

  const handleShow = () => {
    setShow(true);
  };

  const connectMetasmaskForAddress = async (e) => {
    e.preventDefault();
    setShow(false);
    try {
      if (window.ethereum) {
        setislogin(true);
        var web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (accounts[0]) {
          let publicAddress = accounts[0];
          const { signature } = await new Promise((resolve, reject) =>
            web3.eth.personal.sign(
              web3.utils.fromUtf8(`Login Mrmint`),
              publicAddress,
              (err, signature) => {
                if (err) return reject(err);
                return resolve({ publicAddress, signature });
              }
            )
          );
          let res = await RegisterAction({
            address: accounts[0],
            signature: signature,
          });
          if (res.success) {
            setislogin(false);

            toast.success(res.msg);

            Cookies.set("loginSuccessStepMrMint", JSON.stringify(res.data));
            setTimeout(() => {
              // navigate(`${config.baseUrl}dashboard`)
              window.location.href = `${config.baseUrl}dashboard`;
            }, 2000);
          } else {
            setislogin(false);
            toast.error(res.msg);
          }
        }
      } else {
        setislogin(false);
        toast.error("Please use Dapp browser!!");
      }
    } catch (error) {
      setislogin(false);
      switch (error.code) {
        case 4001:
          toast.error("User Rejected");
          break;
        case -32002:
          toast.error(
            "Request Already Processing, Please check your Meta Mask wallet"
          );
          break;
        case "ERR_NETWORK":
          toast.error("We're experiencing technical difficulties.");
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Header />
      {/* <Helmet> */}
      <HomeMetaTag />
      {/* </Helmet> */}
      <Toaster />
      <div className="lighttheme bg-white overflow-hidden">
        <section className="herobanner position-relative text-white overflow-hidden">
          <div className="banner_bg">
            <img
              src="assets/images/banner-before.png"
              className="bannerbefore"
            />
          </div>
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <Fade left>
                  <div className="bannerContent ">
                    <h1>MOVE TO EARN</h1>
                    <p className="mt-4">
                      A journey that’s going to make you Wealthier, Step by
                      Step! Stepmint is the first step for connecting your
                      Health with Wealth. Stepmint integrates fitness and Wealth
                      with real crypto rewards through Web 3.0 technology.
                    </p>
                    <Link>
                      {/* <Button
                        onClick={navigateToPackagePlan1}
                        variant="main2"
                        className="text-white px-5 mt-3"
                      >
                        Get Early Access
                      </Button> */}
                    </Link>
                  </div>
                </Fade>
              </Col>
              <Col lg={6} className="text-center">
                <Zoom>
                  <div className="bannerImage position-relative">
                    <img src="assets/images/banner_img.png" />
                  </div>
                </Zoom>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="ourmission bg-white pt-0">
          <Container className="bg-blue">
            <Row className="align-items-center p-5 gy-3">
              <Col lg={6} className=" position-relative">
                <Slide right>
                  <div className="stepmint-icon d-flex justify-content-center">
                    <img src="assets/images/stepmint-icon.png" />
                  </div>
                  {/* <div className="stepmint_tooltip"> */}
                  {/* <div className=""> */}
                  {/* <span className="mb-0">Step Count Meter</span>
                      <p className="mb-0">
                        <b>289927</b>
                      </p> */}
                  {/* </div> */}
                  {/* </div> */}
                </Slide>
              </Col>
              <Col lg={6} className="">
                <Slide right>
                  <h4 className=" mb-4">
                    <b>OUR MISSION</b>
                  </h4>
                  <p>
                    Stepmint is a revolution meant to inspire individuals from
                    all walks of life to engage in physical activity.
                  </p>
                  <p>
                    Our mission? Well, it revolves around fostering a robust and
                    health-conscious community. We achieve this goal by
                    establishing a revolutionary crypto ecosystem, compelling
                    you to break free from your comfort zone. This will enhance
                    your overall health and grow your wallet simultaneously by
                    blending fitness and wealth-building. It's not just a
                    workout; it's a wealth-building exercise!
                  </p>
                  <Button
                    variant="main2"
                    onClick={navigateToPackagePlan}
                    className="text-white px-5 mt-3"
                  >
                    Subscribe
                  </Button>
                </Slide>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="weightloss bg-gradient position-relative mt-5">
          <Container>
            <Row>
              <Col lg={7} className="">
                <Zoom>
                  <div className="weight-content text-white pt-4">
                    <div className="mb-3">
                      <h4
                        className="text-white text-uppercase"
                        style={{ fontWeight: "600" }}
                      >
                        Benefits Of 10,000 Steps Per Day With Stepmint
                      </h4>
                      {/* <small className="text-white">
                        You’ve probably heard that you should walk 10,000 steps
                        per day for fitness and weight loss.
                      </small> */}
                    </div>
                    <div className="">
                      {/* <p className="text-white mb-3">
                        10,000 Steps will improve you overall health
                      </p> */}
                      <div className="list px-0">
                        <Row>
                          <Col lg={12}>
                            <ul className="pl-0">
                              <li className="d-flex align-items-center">Health Oasis: No sick days ahead!</li>
                              <li className="d-flex align-items-center">Your ticket to a happy mind.</li>
                              <li className="d-flex align-items-center">Revitalize your day with a power surge!</li>
                              <li className="d-flex align-items-center">Strong immunity and better sleep.</li>
                              <li className="d-flex align-items-center">
                                Your passport to an injury-free, vibrant life.
                              </li>
                              <li className="d-flex align-items-center">31% lower mortality risk.</li>
                              <li className="d-flex align-items-center">
                                Stressed? Let every step be your stress
                                vanisher!
                              </li>
                              <li className="d-flex align-items-center">Shedding weight, one step at a time!</li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Zoom>
              </Col>
              <Col lg={5}>
                <div className="weightloss-img">
                  <img src="assets/images/woman-stretching-leg.png" />
                </div>
              </Col>
            </Row>
          </Container>
          <div className="leftstar">
            <img src="assets/images/leftstar.png" />
          </div>
        </section>
        <section className="bg-white sport_community">
          <Container>
            <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4 >
                      <b>Building A Diverse Community</b>
                    </h4>
                    <p className="mb-0">
                      Connect with like-minded individuals, tackle daily tasks,
                      and participate in exhilarating trekking events,
                      marathons, and treasure hunts, enabling you to reap
                      fantastic rewards along the way.
                    </p>
                  </div>
                </Zoom>
              </Col>
              <Col lg={6} className="text-right">
                <Fade right>
                  <img src="assets/images/sportcommunity.png" />
                </Fade>
              </Col>
            </Row>
            <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="text-left">
                <Fade right>
                  <img src="assets/images/health_consultant.png" />
                </Fade>
              </Col>
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4>
                      <b>Health Consultation and Data Security</b>
                    </h4>
                    <p className="mb-0">
                      Stepmint revolutionizes fitness with personalized
                      consultations from doctors and trainers. Whether you aim
                      for weight loss, muscle gain, or holistic wellness, our
                      platform empowers you to achieve your goals. Since your
                      health data is priceless, Stepmint prioritizes its
                      security through Web 3.0 Cosmos, ensuring safe and
                      accessible health records.
                    </p>
                  </div>
                </Zoom>
              </Col>
            </Row>
            <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4>
                      <b>Health with Wealth</b>
                    </h4>
                    <p className="">
                      Wealth opens the doors to a comfy life, education, and
                      personal growth. It's the ticket to security, travel, and
                      pursuing passions. Health, the bedrock of a fulfilling
                      life, lets you relish every moment, chase goals, and build
                      meaningful connections. Striking a balance is a must.
                      Focusing solely on wealth invites stress; neglecting
                      health means limited access to care and comfort.
                      Prioritize health with exercise and wellness. Balancing
                      both is the safe spot, where health enriches wealth
                      benefits, and wealth fuels a healthy lifestyle. It's the
                      ideal path to a vibrant life! With Stepmint you follow
                      this ideal path to a grand lifestyle.
                    </p>
                    {/* <p className="">
                      2. The Foundation of Health: Good physical and mental
                      health forms the basis of a fulfilling life, enabling one
                      to pursue goals, enjoy relationships, and enhance the
                      overall quality of life.
                    </p>
                    <p className="">
                      3. Balancing Act: Prioritizing one over the other—wealth
                      or health—can lead to stress and limitations. Finding
                      equilibrium allows the benefits of wealth to support a
                      healthy lifestyle and vice versa.
                    </p>
                    <p className="">
                      4. The Ideal Path: Striking the right balance ensures that
                      both wealth and health complement each other, resulting in
                      a life well-lived.
                    </p> */}
                  </div>
                </Zoom>
              </Col>
              <Col lg={6} className="text-right">
                <Fade right>
                  <img src="assets/images/health_versus.png" />
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="homepage2">
          <section className="faq pt-0">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="">
                    <div className="side_bg">
                      <img src="assets/home_assets/images/side_bg.png" />
                    </div>
                    <Container className="position-relative">
                      <Row className="justify-content-center align-items-center">
                        <Col lg={6}>
                          <div className="text-center position-relative">
                            <h2 className="subheading text-uppercase faq-heading">
                              FAQ
                            </h2>
                            <img
                              src="assets/home_assets/images/run_man.png"
                              height="701px"
                              style={{ ObjectFit: "contain" }}
                              className="runman"
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="faq-list">
                            <Accordion defaultActiveKey={[0]} flush>
                              {faqsList.map((faq, index) => {
                                return (


                                  <Accordion.Item key={index} eventKey={index} style={{ border: "none" }}>
                                    {console.log(index)}
                                    <Accordion.Header style={{ border: "none" }}>
                                      {faq.question}
                                    </Accordion.Header>
                                    <Accordion.Body style={{ backgroundColor: "#f8f8f8", borderRadius: "0px 0px 10px 10px", fontSize: "small" }}>
                                      {faq.answer}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                )
                              })}
                              {/* <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  What is StepMint.io?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Stepmint is a revolutionary platform that
                                  transforms your daily steps into real crypto
                                  rewards, merging fitness and finance
                                  seamlessly using Web 3.0 technology.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                  How do I get started?
                                </Accordion.Header>
                                <Accordion.Body>
                                  To get started, simply visit our website at
                                  https://stepmint.io/ and subscribe now for
                                  early bird access!
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                  Is the StepMint App free to use?
                                </Accordion.Header>
                                <Accordion.Body>No.</Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                  What payment methods do you accept for premium
                                  plans?
                                </Accordion.Header>
                                <Accordion.Body>
                                  We accept MNT only. Our payment processing is
                                  secure, and your information is always safe.
                                  We accept MNT only. Our payment processing is
                                  secure, and your information is always safe.We
                                  accept MNT only. Our payment processing is
                                  secure, and your information is always safe.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                  How can I contact customer support?
                                </Accordion.Header>
                                <Accordion.Body>
                                  If you have any questions or need assistance,
                                  you can raise tickets. Our customer support
                                  team will get back to you.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                  Is my data safe with StepMint.io?
                                </Accordion.Header>
                                <Accordion.Body>
                                  If you have any questions or need assistance,
                                  you can raise tickets. Our customer support
                                  team will get back to you.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="6">
                                <Accordion.Header>
                                  Can I cancel my premium subscription?
                                </Accordion.Header>
                                <Accordion.Body>
                                  We have a No cancellation policy.
                                </Accordion.Body>
                              </Accordion.Item> */}
                            </Accordion>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="position-relative pt-0">
            <div className="appdownload mobilehide">
              <img src="assets/home_assets/images/app_download.png" />
            </div>
            <div className="app_side side_bg mobileshow">
              <img src="assets/home_assets/images/side_bg.png" />
            </div>

            <Container>
              <Row>
                <Col lg={12}>
                  <div className="">
                    <Container className="position-relative">
                      <Row className="justify-content-center align-items-center">
                        <Col lg={4}>
                          <div>
                            <img src="assets/home_assets/images/phone.png" />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mt-5 pt-5">
                            <h1 className="wait_is_over">Grab the App Today!</h1>
                            <div className="storebtn mt-4">
                              <div className="mb-3">
                                <button className="btn btn-default border bg-white text-left">
                                  <a
                                    href="https://play.google.com/store/apps/details?id=com.espsofttech.stepmint"
                                    target="_blank"
                                    className="text-decoration-none "
                                    style={{ color: "#212529" }}
                                  >
                                    <div className="d-flex">
                                      <div>
                                        <img src="assets/home_assets/images/googleplay.png" />
                                      </div>

                                      <div className="text-left">
                                        <small>Get it On</small>
                                        <div>
                                          <span className="fw-bold">
                                            Google Play
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </button>
                              </div>
                              <div>
                                <button className="btn btn-default border bg-white text-left">
                                  <div className="d-flex">
                                    <div className="">
                                      <img src="assets/home_assets/images/apple.png" />
                                    </div>
                                    <div className="text-left">
                                      <a
                                        href="https://apps.apple.com/in/app/step-mint/id6473780792"
                                        target="_blank"
                                        className="text-decoration-none "
                                        style={{ color: "#212529" }}
                                      >
                                        <small>Get it On</small>
                                        <div>
                                          <span className="fw-bold">
                                            App Store
                                          </span>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="family">
                            <img src="assets/home_assets/images/family.png" />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        {/* <section className="getstarted bg-white p-0 pb-5">
          <Container className="bg-blue position-relative">
            <Row className="align-items-center">
              <Col lg={6} className="">
                <Slide right>
                  <div className="p-3">
                    <h2>Join Stepmint:</h2>
                    <h4 className="pt-2 pb-2">
                      <b>Your Guide to the New World of Wealthy Fitness.</b>
                    </h4>
                    <Button variant="main2" onClick={navigateToPackagePlan} className="text-white px-5 md-md-3">
                      Subscribe
                    </Button>
                  </div>
                </Slide>
              </Col>
              <Col lg={6} className=" text-center">
                <div className="sport-girl">
                  <img src="assets/images/sportgirl.png" />
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
      </div>

      <Modal
        show={false}
        onHide={handleClose}
        backdrop="static"
        className="Nftmodal"
        size="lg"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="btn-rounded p-0"><a href={`${config.baseUrl}`} style={{ textDecoration: "none" }} className="d-flex flex-column justify-content-center">
          <Image className="rounded" src="assets/images/alertpopup2.jpg" fluid />
          <p className="text-center mt-3" >  In the meantime, you can get the APK from our Telegram group - <a href="https://t.me/stepmintcommunity" target="_blank">@stepmintcommunity</a></p>
          <p className="text-center mb-3"> Download App Now </p>
          <div className="text-center mb-3"><a href="https://stepmint.io/app/app-release.apk"
            target="_blank"
            style={{ textDecoration: "none" }}
            variant=""
            className="text-white px-4 py-2 ms-2 main2"
          >
            <IoLogoAndroid style={{ width: 20, height: 20 }} /> Android
          </a>
            <a href="https://apps.apple.com/in/app/step-mint/id6473780792"
              style={{ textDecoration: "none" }}
              target="_blank"
              variant=""
              className="text-white px-4 py-2 ms-2 main2"
            >
              <FaApple style={{ width: 20, height: 20 }} /> IOS
            </a>


          </div>


        </a>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};
export default Home;
