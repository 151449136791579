import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Tree from "react-d3-tree";
import { TextField, Box } from "@mui/material";
import "../custom-tree.css";

import Dashboardheader from "../directives/dashboardheader";
import { forgetpasswordAction, resetpasswordAction } from "../Action/user.action";
import toast from "react-hot-toast";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Changepassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [form, setForm] = useState({ newPassword: "", confirmPassword: "" });
  const [errors, setErrors] = useState({});

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setForm((oldForm) => ({ ...oldForm, [name]: value }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!form.newPassword.trim()) {
      formErrors.newPassword = "New Password is required.";
    } else if (form.newPassword.length < 8) {
      formErrors.newPassword = "Password must be at least 8 characters long.";
    } else if (!/[A-Z]/.test(form.newPassword)) {
      formErrors.newPassword = "Password must contain an uppercase letter.";
    } else if (!/[a-z]/.test(form.newPassword)) {
      formErrors.newPassword = "Password must contain a lowercase letter.";
    } else if (!/[0-9]/.test(form.newPassword)) {
      formErrors.newPassword = "Password must contain a number.";
    } else if (!/[!@#$%^&*]/.test(form.newPassword)) {
      formErrors.newPassword = "Password must contain a special character.";
    } else if (/\s/.test(form.newPassword)) {
      formErrors.newPassword = "Password cannot contain spaces.";
    }

    if (!form.confirmPassword.trim()) {
      formErrors.confirmPassword = "Confirm Password is required.";
    } else if (form.newPassword !== form.confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  console.log(errors)
  const togglePasswordVisibility = (field) => {
    if (field === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleResetPassword = async () => {
    if (!validateForm()) return;

    try {
      const res = await resetpasswordAction({ newPassword: form.newPassword, token });
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          navigate(`${config.baseUrl}login`);
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const orgChart = {
    name: "CEO",
    children: [
      {
        name: "Manager",
        attributes: {
          department: "Production",
        },
        children: [
          {
            name: "Foreman",
            attributes: {
              department: "Fabrication",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
          {
            name: "Foreman",
            attributes: {
              department: "Assembly",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
        ],
      },
    ],
  };
  return (
    <>

      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-4">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h1 className="text-gradient">Change Your Password </h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="changepassword mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="card card-view1 rounded-20 p-4">
                    <div className="card-body p-3">
                      <Row className="align-items-center">
                        <Col lg={6} className="mb-3">
                          <div>
                            <img
                              src="assets/images/key.png"
                              width="100%"
                              className="key_img"
                            />
                          </div>
                        </Col>
                        <Col lg={6} className="mb-3">
                          <div className="card-view1 rounded-20 p-4">
                            <Row>
                              <Col lg={7} md={8} sm={8}>
                                <h4 className="text-white">
                                  <b>Change Password</b>
                                </h4>
                                <p>
                                  Just type it twist and try not to forget it.
                                  Password should be and must contain.
                                </p>
                              </Col>
                              <Col lg={5} md={4} sm={4}>
                                <div
                                  className="text-center lock"
                                >
                                  <img src="assets/images/lock.png" />
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div>
                                  <ul>
                                    <li>
                                      <div>
                                        <h5 className="text-white mb-0">
                                          <b>8+</b>
                                        </h5>
                                        <h6 className="text-grey mt-1">Characters</h6>
                                      </div>
                                    </li>
                                    <li>
                                      <div>
                                        <h5 className="text-white mb-0">
                                          <b>AA</b>
                                        </h5>
                                        <h6 className="text-grey mt-1">Uppercase</h6>
                                      </div>
                                    </li>
                                    <li>
                                      <div>
                                        <h5 className="text-white mb-0">
                                          <b>aa</b>
                                        </h5>
                                        <h6 className="text-grey mt-1">Lowercase</h6>
                                      </div>
                                    </li>
                                    <li>
                                      <div>
                                        <h5 className="text-white mb-0">
                                          <b>123</b>
                                        </h5>
                                        <h6 className="text-grey mt-1">Number</h6>
                                      </div>
                                    </li>
                                    <li>
                                      <div>
                                        <h5 className="text-white mb-0">
                                          <b>@$# </b>
                                        </h5>
                                        <h6 className="text-grey mt-1">Symbol</h6>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </Col>
                              <Col lg={12} className="mb-4">
                                {/* <div className="mb-4"> */}
                                {/* <TextField
                                      id="outlined-helperText"
                                      label="Enter Your Old Password"
                                      type="password"
                                      style={{ width: "100%" }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      autoComplete="off"
                                    /> */}
                                {/* </div> */}
                                <div className="position-relative">
                                  <input
                                    className="form-control password-input"
                                    placeholder="New password"
                                    onChange={inputHandler}
                                    name="newPassword"
                                    type={showNewPassword ? "text" : "password"} // Toggle type
                                    style={{ width: "100%", color: 'white' }}
                                    InputLabelProps={{ shrink: true }}
                                    autoComplete="off"
                                    // error={!!errors.newPassword}
                                    // helperText={errors.newPassword}
                                  />
                                  <div
                                    className="eye-icon2"
                                    onClick={() => togglePasswordVisibility("newPassword")}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {showNewPassword ? <FaEye style={{ color: "#6c757d", width: 20, height: 20 }} /> : <FaEyeSlash style={{ color: "#6c757d", width: 20, height: 20 }} />}

                                  </div> 
                                </div>
                                {errors.newPassword && (
                                  <div className="" style={{ color: "red" }}>
                                    {errors.newPassword}
                                  </div>
                                  )} 
                              </Col>
                              <br />
                              <Col lg={12}>
                                <div className="position-relative">
                                  <input
                                    className="form-control password-input"
                                    placeholder="Confirm password"

                                    onChange={inputHandler}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword}
                                    name="confirmPassword"
                                    type={showConfirmPassword ? "text" : "password"} // Toggle type
                                    style={{ width: "100%", color: "white" }}
                                    InputLabelProps={{ shrink: true }}
                                    autoComplete="off"
                                  />
                                  <div
                                    className="eye-icon2"
                                    onClick={() => togglePasswordVisibility("confirmPassword")}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {showConfirmPassword ? <FaEye style={{ color: "#6c757d", width: 20, height: 20 }} /> : <FaEyeSlash style={{ color: "#6c757d", width: 20, height: 20 }} />}

                                  </div>
                                  </div>
                                  {errors.confirmPassword && (
                                  <div className="" style={{ color: "red" }}>
                                    {errors.confirmPassword}
                                  </div>
                                  )} 

                                <br />
                              </Col>
                                <Button variant="main2" onClick={handleResetPassword} type="submit" className="w-100 ">
                                  Change Password
                                </Button>
                             
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Changepassword;
