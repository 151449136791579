import React from 'react';
import { Helmet } from 'react-helmet';

const PackagePlanMetaTag = () => {
  return (
    <Helmet>
       <meta charset="UTF-8" />
       <meta name="description" content="StepMint.io offers flexible package plans for its walk and earn money app, enabling users to maximize rewards easily through engaging in Web3 activities." />
        <meta name="title" content="walk and earn money app|best walking app to earn money|walk earn money" />
        <meta name="keywords" content="walk and earn money app, best walking app to earn money, walk earn money, earn money by walking apps, walk earn money, best app to earn money for walking" />
        <title>walk and earn money app|best walking app to earn money|walk earn money</title>
      {/* Organization JSON-LD */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "StepMint",
          "alternateName": "StepMint",
          "url": "https://stepmint.io/",
          "logo": "https://stepmint.io/assets/images/logo.png",
          "sameAs": [
            "https://www.facebook.com/profile.php?id=61551542573562",
            "https://twitter.com/step_mint",
            "https://instagram.com/stepmint?igshid=MzMyNGUyNmU2YQ==",
            "https://www.linkedin.com/company/stepmint/about/?viewAsMember=true"
          ]
        }
        `}
      </script>

      {/* Website JSON-LD */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "StepMint",
          "url": "https://stepmint.io/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "{search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }
        `}
      </script>

      {/* Meta Tags */}
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content="walk and earn money app|best walking app to earn money|walk earn money" />
      <meta property="og:url" content="https://stepmint.io/packageplan" />
      <meta property="og:image" content="https://stepmint.io/media/Walker-min.png" />
      <meta property="og:description" content="StepMint.io offers flexible package plans for its walk and earn money app, enabling users to maximize rewards easily through engaging in Web3 activities." />
      <meta property="business:contact_data:street_address" content="322, Magneto Offizo, Magneto Mall" />
      <meta property="business:contact_data:locality" content="Raipur" />
      <meta property="business:contact_data:region" content="Chhattisgarh" />
      <meta property="business:contact_data:postal_code" content="492001" />
      <meta property="business:contact_data:country_name" content="India" />

      {/* Website Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="walk and earn money app|best walking app to earn money|walk earn money" />
      <meta property="og:url" content="https://stepmint.io/packageplan" />
      <meta property="og:image" content="https://stepmint.io/media/Walker-min.png" />
      <meta property="og:description" content="StepMint.io offers flexible package plans for its walk and earn money app, enabling users to maximize rewards easily through engaging in Web3 activities." />
    </Helmet>
  );
};

export default PackagePlanMetaTag;
