import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Form, Row, Col, Spinner, Container } from 'react-bootstrap';
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { sendOtpForVerificationAction, verifyOtpForPhoneVerificationAction, verifyOtpForVerificationAction } from '../../Action/user.action';

const EmailVerificationModal = ({ showModal, setshowModal, email, mobile, verificationStatus, loading, countryCode, timer, setTimer, setIsTimerActive, isTimerActive, handleVerifyPhone, handleVerifyEmail }) => {
    const [otp, setOtp] = useState(['', '', '', '']);
    const [mobileOtp, setMobileOtp] = useState(['', '', '', '', '', '']);
    const [otpIs, setOtpIs] = useState('');
    const inputRefs = useRef([]);
    const userDetailsforPhoneVerification = JSON.parse(localStorage.getItem('userDetailsforPhoneVerification'));

    const loginData = !Cookies.get("loginSuccessStepMrMint", {
        domain: "mrmint.io",
    })
        ? []
        : JSON.parse(
            Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
        );

    useEffect(() => {
        let countdown;
        if (isTimerActive && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(countdown);
            setIsTimerActive(false);
        }
        return () => clearInterval(countdown);
    }, [isTimerActive, timer]);

    const handleOtpChange = (value, index, otpType) => {
        const newOtp = [...(otpType === 'email' ? otp : mobileOtp)];
        newOtp[index] = value;
        otpType === 'email' ? setOtp(newOtp) : setMobileOtp(newOtp);

        if (value.length === 1 && index < newOtp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index, otpType) => {
        const currentOtp = otpType === 'email' ? otp : mobileOtp;
        if (e.key === "Backspace" && currentOtp[index] === '' && index > 0) {
            const newOtp = [...currentOtp];
            newOtp[index - 1] = '';
            otpType === 'email' ? setOtp(newOtp) : setMobileOtp(newOtp);
            inputRefs.current[index - 1].focus();
        }
    };

    useEffect(() => {
        const otpValue = verificationStatus === 'email' ? otp.join('') : mobileOtp.join('');
        setOtpIs(otpValue);
    }, [otp, mobileOtp, verificationStatus]);

    const verifyOtpForVerification = async () => {
        if (otpIs.length === 0) {
            toast.error('OTP is required');
            return;
        }
        try {
            let res;
            if (verificationStatus === "email") {
                const emailData = { email, otp: otpIs };
                res = await verifyOtpForVerificationAction(emailData);

                if (res.success) {
                    toast.success(res.msg);
                    loginData.is_email_verify = 1;
                    Cookies.set("loginSuccessStepMrMint", JSON.stringify(loginData));
                }
            } else if (verificationStatus === "mobile") {
                const mobileData = {
                    phone: mobile,
                    countryCode,
                    otp: otpIs,
                    orderId: userDetailsforPhoneVerification.orderId
                };
                res = await verifyOtpForPhoneVerificationAction(mobileData);
                if (res.success) {
                    toast.success(res.msg);
                    loginData.is_phone_verify = true;
                    Cookies.set("loginSuccessStepMrMint", JSON.stringify(loginData));
                }
            }
            if (res.success) {
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            show={showModal}
            onHide={() => setshowModal(false)}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="w-100 text-center">
                    <h5 className="mb-0">OTP Verification</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className='d-flex flex-column justify-content-center align-items-center'>
                    {verificationStatus === 'email' ? (
                        <p className='text-center'>Enter the code that we sent to your email: {email}</p>
                    ) : (
                        <p className='text-center'>Enter the code that we sent to your phone number : +{countryCode} {mobile}</p>
                    )}
                    <Row className="mb-4 d-flex justify-content-center">
                        {(verificationStatus === 'email' ? otp : mobileOtp).map((value, index) => (
                            <Col key={index} xs={2} className='d-flex justify-content-center align-items-center'>
                                <Form.Control
                                    type="text"
                                    maxLength="1"
                                    value={value}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    className='text-center'
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (/^\d*$/.test(inputValue)) {
                                            handleOtpChange(inputValue, index, verificationStatus);
                                        }
                                    }}
                                    onKeyDown={(e) => handleKeyDown(e, index, verificationStatus)}
                                />
                            </Col>
                        ))}
                    </Row>
                    <p className="text-center mb-4">Time remaining: {timer} seconds</p>
                    <Button
                        variant="main2"
                        className="text-white w-50"
                        style={{ height: 45 }}
                        onClick={isTimerActive ? verifyOtpForVerification : () => {
                            setIsTimerActive(true);
                            verificationStatus === 'email' ? handleVerifyEmail('email') : handleVerifyPhone('mobile');
                        }}
                    >
                        {isTimerActive ? "Verify OTP" : loading ? (
                            <Spinner animation="border" size="sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : "Resend OTP"}
                    </Button>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default EmailVerificationModal;
