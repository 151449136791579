import React, { useEffect, useState } from "react";
import { exportLoyaltyBounsData } from "../Action/user.action";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import { IoIosArrowRoundBack } from "react-icons/io";

function LoyaltyBonusDetails() {
    let { loyaltyDetailsDate } = useParams();
    const [page, setPage] = useState(0);
    const [detailsData, setDetailsData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false); // Loading state for initial load
    const [isInitialLoad, setIsInitialLoad] = useState(true); // Separate state for first load
    const itemsPerPage = 10;
    const [formattedDate, setFormattedDate] = useState("");

    const formatDate = (loyaltyDetailsDate) => {
        const date = new Date(loyaltyDetailsDate);
        const options = { year: "numeric", month: "short", day: "numeric" };
        setFormattedDate(date.toLocaleDateString("en-GB", options).replace(/ /g, "-"));
    };

    const handlePageChange = ({ selected }) => {
        setPage(selected);
    };

    useEffect(() => {
        if (isInitialLoad) {
            setLoading(true);
        }
        getLoyaltyBonusDetails();
        formatDate(loyaltyDetailsDate);
    }, [page]);

    const getLoyaltyBonusDetails = async () => {
        let res = await exportLoyaltyBounsData({ date: loyaltyDetailsDate, page: page + 1, itemsPerPage });

        if (res.success) {
            if (!res.data.rows.length) {
                toast.error("No record found!");
            } else {
                setDetailsData(res.data.rows);
                setTotalPages(res.data.totalPages);
            }
        } else {
            toast.error("Failed to fetch data");
        }

        setLoading(false); // Hide loading once data is fetched
        setIsInitialLoad(false); // Set initial load to false after the first data fetch
    };

    return (
        <>
            <div className="mt-5 mb-5">
                <div className="container referral">
                    <div className="mr-referral-content d-flex justify-content-between">
                        <div className="mr-referral-block">
                            <h2 className="text-white" style={{ fontWeight: 600 }}>Loyalty Bonus Details</h2>
                        </div>
                        <div className="text-light text-end d-flex align-items-center justify-content-end">
                            <span style={{ fontWeight: 400, marginRight: "10px" }}>Date:</span>
                            <p style={{ fontWeight: 700, textTransform: "capitalize", letterSpacing: 1.5, color: "white", margin: 0 }}>{formattedDate}</p>
                        </div>
                    </div>
                </div>
            </div>

            <Container>
                {loading && isInitialLoad ? ( // Show loading only for initial load
                    <div className="d-flex justify-content-center align-items-center " style={{ height: "50vh" }}>
                        <Spinner animation="border" role="status" style={{ color: "#6389FF" }} className="mb-5">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <table width="100%" className="text-center card-view">
                            <thead>
                                <tr className="table-head px-4">
                                    <th>S.No</th>
                                    <th>NFT Id</th>
                                    <th>Level</th>
                                    <th>Amount</th>
                                    <th>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detailsData.length === 0 ? (
                                    <tr className="tr_list">
                                        <td colSpan="5">No Details Available</td>
                                    </tr>
                                ) : (
                                    detailsData.map((detail, index) => (
                                        <tr key={index} className="tr_list">
                                            <td>{page * itemsPerPage + index + 1}</td>
                                            <td>{detail.uniqueid}</td>
                                            <td>{detail.level}</td>
                                            <td>{parseFloat(detail.amount).toFixed(4)}
                                            &nbsp;MNT</td>
                                            <td>{detail.percent}%</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        {totalPages > 1 && (
                            <div className="pagination-container">
                                <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    breakLabel={"..."}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    previousClassName={"btn"}
                                    nextClassName={"btn"}
                                    forcePage={page}
                                />
                            </div>
                        )}
                    </>
                )}
            </Container>
        </>
    );
}

export default LoyaltyBonusDetails;
