import React, { Component, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import MainHeaderLayout from "./MainHeaderLayout";
import DashboardHeaderLayout from "./DashboardHeaderLayout";
import config from "./config/config";
import "./App.css";
import Home from "./component/home";
// import Home from "./component/HomeComponent/Home";
import Aboutus from "./component/aboutus";
import Bnbstaking from "./component/bnbstaking";
import Faq from "./component/faq";
import Stakenft from "./component/stakenft";
import Contactus from "./component/contactus";
import Signup from "./component/signup";
// import Login from "./component/NewLogin/login";
import Success from "./component/success";
import Forgetpassword from "./component/forgetpassword";
// -----------------------
import Dashboard from "./component/dashboard";
import Wallethistory from "./component/wallethistory";
import Deposit from "./component/deposit";
import Matchingbonus from "./component/matchingbonus";
import Packageplan from "./component/packageplan";
import Withdraw from "./component/withdraw";
import Profile from "./component/profile";
// ---------------
import Staking from "./component/staking";
import Buyhistory from "./component/buyhistory";
import Referral from "./component/referral";
import Earninghistory from "./component/earninghistory";
import Nftdetail from "./component/nftdetail";
import Referraltree from "./component/referraltree";
import Changepassword from "./component/changepassword";
import Howitwork from "./component/howitwork";
import { Helmet } from "react-helmet";
import TermsAndConditions from "./component/TermsAndCondition";
import FreeSignupTerms from "./component/FreeSignupTerms";


import PrivacyPolicy from "./component/PrivacyPolicy";
import MobileDeposit from "./component/MobileDeposit";
import DeleteUser from "./component/DeleteUser";
import MintNft from "./component/MintNft";

// --------------------

import Stepmint from "./component/Stepmintmobile";
import Mobilefooter from "./component/Stepmintmobile/mobilefooter";
import NewHome from "./component/NewHome";
import BuyNowMobile from "./component/BuyNowMobile";
import BuyNow2 from "./component/BuyNow2";
import StepDashboard from "./component/StepDashboard";
import LDP from "./component/LDP";
import LdpQualifier from "./component/LdpQualifierComponent/LdpQualifier";
import CouponValidator from "./component/CouponValidator/CouponValidator";
import LDP1Qualifier from "./component/LdpQualifierComponent/LDP1Qualifier";
import LDP2Qualifier from "./component/LdpQualifierComponent/LDP2Qualifier";
import ScrollToTop from "./directives/ScrollToTop";
import ProtectedRoutes from "./directives/ProtectedRoutes";
import Cookies from "js-cookie";
import LoyaltyBonusDetails from "./component/LoyaltyBonusDetails";
import Login from "./component/newLogin/login";
import Amlpolicy from "./component/AmlPolicy";
import Disclaimer from "./component/Disclaimer";
import Riskdisclosure from "./component/Riskdisclosure";
import Ticketterms from "./component/Ticketterms";
import PlanTermsAndConditions from "./component/planTermsAndConditions";
import SignupTermsCondition from "./component/SignupTermsCondition";

const RouterComponent = () => {

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );
  return (
    <BrowserRouter>
      {/* <Toaster /> */}
      <ScrollToTop />
      <div>
        <Helmet>
          <meta charset="UTF-8" />
          <meta
            name="description"
            content="Start earning with the best way to make money online today by joining Stepmint. Join millions of people who are living healthier and wealthier lives with this platform."
          />
          <meta
            name="title"
            content="Unlock Wealth and Wellness | Start Earning with Stepmint Today"
          />
          <meta
            name="keywords"
            content="Stepmint, make money online, wealth, wellness, earning online"
          />
          <meta name="author" content="StepMint" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        {/* <UserProvider> */}
        <Routes>
          {/* {/ <Route path={`${config.baseUrl}`} element={<Home />} /> /} */}
          <Route path={`${config.baseUrl}`} element={<Home />} />
          <Route
            path={`${config.baseUrl}howitwork`}
            element={<Howitwork />}
          />
          <Route
            path={`${config.baseUrl}contactus`}
            element={<Contactus />}
          />
          <Route path={`${config.baseUrl}aboutus`} element={<Home />} />
          <Route path={`${config.baseUrl}signup`} element={<Signup />} />
          <Route
            path={`${config.baseUrl}signup/:referral_address`}
            element={<Signup />}
          />

          <Route
            path={`${config.baseUrl}app_launch_event_dec_2023`}
            element={<NewHome />}
          />
          <Route
            path={`${config.baseUrl}termsandconditions`}
            element={<TermsAndConditions />}
          />
          <Route
            path={`${config.baseUrl}amlpolicy`}
            element={<Amlpolicy />}
          />
          <Route
            path={`${config.baseUrl}disclaimer`}
            element={<Disclaimer />}
          />
          <Route
            path={`${config.baseUrl}riskdisclosure`}
            element={<Riskdisclosure />}
          />
          <Route
            path={`${config.baseUrl}ticketterms`}
            element={<Ticketterms />}
          />
          <Route
            path={`${config.baseUrl}plantermsandconditions`}
            element={<PlanTermsAndConditions />}
          />
          <Route
            path={`${config.baseUrl}signuptermsandconditions`}
            element={<SignupTermsCondition />}
          />
          <Route
            path={`${config.baseUrl}freesignupterms`}
            element={<FreeSignupTerms />}
          />
          <Route
            path={`${config.baseUrl}privacypolicy`}
            element={<PrivacyPolicy />}
          />
          <Route
            path={`${config.baseUrl}authtoken/deleteAccount/:authToken`}
            element={<DeleteUser />}
          />
          <Route
            path={`${config.baseUrl}packageplan`}
            element={<Packageplan />}
          />
          <Route
            path={`${config.baseUrl}nftdetail/:id`}
            element={<BuyNow2 />}
          />
          <Route
            path={`${config.baseUrl}nftdetail`}
            element={<BuyNow2 />}
          />
          {/* -------Mobile------- */}
          <Route
            path={`${config.baseUrl}stepmint_mobile`}
            element={<Stepmint />}
          />
          <Route
            path={`${config.baseUrl}mobilefooter`}
            element={<Mobilefooter />}
          />
          <Route
            path={`${config.baseUrl}loyaltyBonusDetails/:loyaltyDetailsDate`}
            element={<LoyaltyBonusDetails />}
          />
          <Route path={`${config.baseUrl}login`} element={<Login />} />
          <Route
            path={`${config.baseUrl}forgetpassword`}
            element={<Forgetpassword />}
          />
          <Route
            path={`${config.baseUrl}changepassword/:token`}
            element={<Changepassword />}
          />

          {/* {/ Dashboard Header /} */}
          <Route element={<ProtectedRoutes />}>
            <Route element={<DashboardHeaderLayout />}>
              <Route
                path={`${config.baseUrl}mintnft/:id`}
                element={<MintNft />}
              />
              <Route
                path={`${config.baseUrl}bnbstaking`}
                element={<Bnbstaking />}
              />
              <Route path={`${config.baseUrl}faq`} element={<Faq />} />
              <Route
                path={`${config.baseUrl}dashboard`}
                element={<Dashboard />}
              />
              <Route
                path={`${config.baseUrl}wallethistory`}
                element={<Wallethistory />}
              />
              <Route
                path={`${config.baseUrl}deposit`}
                element={<Deposit />}
              />
              <Route
                path={`${config.baseUrl}matchingbonus`}
                element={<Matchingbonus />}
              />

              <Route
                path={`${config.baseUrl}useractivity`}
                element={<StepDashboard />}
              />

              <Route
                path={`${config.baseUrl}withdraw`}
                element={loginData?.is_email_verify && loginData?.bnb_address ? <Withdraw /> : <Wallethistory />}
              />
              <Route
                path={`${config.baseUrl}profile`}
                element={<Profile />}
              />
              <Route
                path={`${config.baseUrl}mdeposit`}
                element={<MobileDeposit />}
              />

              <Route
                path={`${config.baseUrl}referral`}
                element={<Referral />}
              />
              <Route
                path={`${config.baseUrl}buyfromblockchain/:id`}
                element={<BuyNowMobile />}
              />
              <Route
                path={`${config.baseUrl}BuyNowMobile`}
                element={<BuyNowMobile />}
              />
              <Route
                path={`${config.baseUrl}coupon-validator`}
                element={<CouponValidator />}
              />
              <Route
                path={`${config.baseUrl}ldp1`}
                element={<LDP1Qualifier />}
              />
              <Route
                path={`${config.baseUrl}ldp2`}
                element={<LDP2Qualifier />}
              />
              <Route
                path={`${config.baseUrl}referraltree`}
                element={<Referraltree />}
              />
              <Route
                path={`${config.baseUrl}stepmint_mobile`}
                element={<Stepmint />}
              />
              <Route
                path={`${config.baseUrl}mobilefooter`}
                element={<Mobilefooter />}
              />
              <Route
                path={`${config.baseUrl}ldpQualifier`}
                element={<LdpQualifier />}
              />

            </Route>
          </Route>
        </Routes>
        {/* </UserProvider> */}
      </div>
    </BrowserRouter>
  );
};
export default RouterComponent;