import React, { useEffect, useState } from "react";
import { getTransactionSummary } from "../../Action/user.action";
import ReactPaginate from "react-paginate";


const Passbook = () => {
    const [transactionSummary, setTransactionSummary] = useState([])
    const itemsPerPage = 10; // Set the number of items per page
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        getTransactionSummaryData()
    }, [])

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const getTransactionSummaryData = async () => {
        let res = await getTransactionSummary();
        if (res.success) {
            setTransactionSummary(res.data)
        }
    }

    const totalPages = Math.ceil(transactionSummary.length / itemsPerPage);
    const totalPageswithdraw = Math.ceil(
        transactionSummary.length / itemsPerPage
    );
    const isOnFirstPage = currentPage == 0;
    const isOnLastPage = currentPage == totalPages - 1;
    const isOnLastPageWithdraw = currentPage == totalPageswithdraw - 1;

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const transactionSummarySlice = transactionSummary.slice(
        startIndex,
        endIndex
    );

    return (
        <div className="table-responsive">
            <table width="100%">
                <thead>
                    <tr className="table-head">
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Step Reward</th>
                        <th>Referral</th>
                        <th>Community Reward</th>
                        <th>Loyalty Bonus</th>
                        <th>Withdraw</th>
                        <th>Transfer</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {transactionSummary.length == 0 ? (
                        <tr className="tr_list">
                            <td colspan="6">No Data Available</td>
                        </tr>
                    ) : (
                        transactionSummarySlice.map(
                            (item, index) => (
                                <tr class="tr_list">
                                    <td>
                                        {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>
                                        {item.date}
                                    </td>
                                    <td>{item.step_reward}&nbsp;MNT</td>
                                    <td>{item.referral}</td>
                                    <td>
                                        {item.community_reward}
                                    </td>
                                    <td>
                                        {item.loyalty_bonus}
                                    </td>
                                    <td>
                                        {item.withdraw}
                                    </td>
                                    <td>
                                        {item.transfer}
                                    </td>
                                    <td>
                                        {item.running_total}
                                    </td>
                                </tr>
                            )
                        )
                    )}
                </tbody>
            </table>
            {transactionSummary.length > itemsPerPage && (
                <div className="pagination-container">
                    <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        previousClassName={
                            isOnFirstPage ? "btn  disabled" : ""
                        }
                        nextClassName={
                            isOnLastPageWithdraw ? "btn  disabled" : ""
                        }
                        previousLinkClassName={
                            isOnFirstPage ? "disabled-link" : ""
                        }
                        nextLinkClassName={
                            isOnLastPageWithdraw ? "disabled-link" : ""
                        }
                        breakLabel={"..."}
                        pageCount={Math.ceil(
                            transactionSummary.length / itemsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                    />
                </div>
            )}
        </div>
    )
}

export default Passbook;