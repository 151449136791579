import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Dropdown,
} from "react-bootstrap";

import Dashboardheader from "../directives/dashboardheader";
import Cookies from "js-cookie";
import "../component/dashboard.css";
import {
  UserPurchasedPlan,
  DashboardStatistics,
  LdpUpdateAction,
  GetOtpAction,
  GetLdp2Action,
  Ldp2UpdateAction,
} from "../Action/user.action";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import Web3 from "web3";
import SwiperComponent from "../directives/Swiperr";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [showLdpModal, setShowLdpModal] = useState(false);
  const [showLdp2Modal, setShowLdp2Modal] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const [dashboarddata, setdashboarddata] = useState({});
  const [userdatahistory, setuserdatahistory] = useState([]);
  const [userdataid, setuserdataid] = useState([]);
  const [usernft, setusernft] = useState("");
  const [getQrData, setQrData] = useState([]);
  const [getLdp2Data, setLdp2Data] = useState([]);
  const [currentModal, setCurrentModal] = useState(null);

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );

  var web3 = new Web3(window.ethereum);
  let MrmintContractCall = new web3.eth.Contract(
    config.mrmintContractAbi,
    config.contractAddressForRecharge
  );

  let UsdtContractCall = new web3.eth.Contract(
    config.mrmintContractAbi,
    config.contractAddressForRechargeUSDT
  );

  useEffect(() => {
    UsersPlan();
    setTimeout(() => { }, 1000);
    dynamicLink();
    getQrCodeData();
  }, []);

  const getQrCodeData = async () => {
    let res = await GetOtpAction();
    if (res.success) {
      setQrData(res.data);
      if (res.data?.isAttending) {
        handleUpdateState();
      } else {
        getLdp2UserData();
      }
    } else {
      setQrData([]);
    }
  };

  const getLdp2UserData = async () => {
    let res = await GetLdp2Action();
    if (res.success) {
      setLdp2Data(res.data);
      if (res.data?.isAttending) {
        handleUpdateStateLdp2();
      }
    } else {
      setQrData([]);
    }
  };

  const handleLdpClose = () => {
    setShowLdpModal(false);
    getLdp2UserData();
  };

  const handleLdp2Close = () => {
    setShowLdp2Modal(false);
    setCurrentModal(null);
  };

  const handleUpdateState = () => {
    setShowLdpModal(true);
  };

  const handleUpdateStateLdp2 = () => {
    setShowLdp2Modal(true);
  };

  const dynamicLink = () => {
    const regex = /#subscribedplan\/(\w+)/;
    let url = window.location.href;
    const match = url.match(regex);
    const hashIndex = url.indexOf("#");
    if (hashIndex !== -1) {
      let hash = url?.slice(hashIndex + 1);
      if (hash === "subscribedplan") {
        let targetElement = document.getElementById("subscribedplan");
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    } else {
      return;
    }
    // const parts = url.split('/');
    // const lastPart = parts[parts.length - 1];
    // setlastparturl(lastPart)
    // console.log(lastPart);
  };

  const redirection = () => {
    window.location.href = `${config.baseUrl}packageplan`;
  };
  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    console.log(data);

    setuserdataid(data);
    setusernft(data.uniqueId);
    setTimeout(() => {
      setShow(true);
    }, 1000);
  };

  const UsersPlan = async () => {
    let res2 = await DashboardStatistics();
    if (res2.success) {
      setdashboarddata(res2.data);
    } else {
      setdashboarddata([]);
    }

    let res1 = await UserPurchasedPlan();
    if (res1.success) {
      if (res1.data == null) {
        setuserdata([]);
        setuserdatahistory([]);
      } else {
        console.log(res1.data.myplan);
        setuserdata(res1.data.myplan);
        setuserdatahistory(res1.data.history);
      }
    } else {
      // setuserdata(res.msg)
      setuserdata([]);
      setuserdatahistory([]);
    }
  };

  // const handleMetamaskPayment = async (args, amount) => {
  //   setLoader(true)
  //   try {
  //     let value = amount * 10 ** 18;
  //     value = value.toString();
  //     let approval;
  //     if (args == "MNT") {
  //       approval = await MrmintContractCall.methods.transfer(
  //         config.depositAddress,
  //         value
  //       );
  //     } else {
  //       approval = await UsdtContractCall.methods.transfer(
  //         config.depositAddress,
  //         value
  //       );
  //     }

  //     let encoded_tx = approval.encodeABI();
  //     let gasPrice = await web3.eth.getGasPrice();
  //     let gasLimit = await web3.eth.estimateGas({
  //       gasPrice: web3.utils.toHex(gasPrice),
  //       to: args == "MNT" ? config.contractAddressForRecharge : config.contractAddressForRechargeUSDT,
  //       from: loginData?.bnb_address,
  //       data: encoded_tx,
  //     });
  //     let trx = await web3.eth.sendTransaction({
  //       gasPrice: web3.utils.toHex(gasPrice),
  //       gas: web3.utils.toHex(gasLimit),
  //       to: args == "MNT" ? config.contractAddressForRecharge : config.contractAddressForRechargeUSDT,
  //       from: loginData?.bnb_address,
  //       data: encoded_tx,
  //     });
  //     if (trx.transactionHash) {
  //       setApproval("complete");
  //       setLoader(false)
  //       return true;
  //     } else {
  //       setApproval("failed");
  //       setTransaction("failed");
  //       setLoader(false)
  //       toast.error("Something went wrong");
  //       // setTimeout(() => {
  //       //   window.location.reload();
  //       // }, 3000);
  //       return false;
  //     }
  //   } catch (error) {
  //     setApproval("failed");
  //     setTransaction("failed");
  //     setLoader(false)
  //     toast.error(error.message);
  //     // setTimeout(() => {
  //     //   window.location.reload();
  //     // }, 3000);
  //     return false;
  //   }
  // };

  const handleUpdateLdp = async (
    isAttending,
    id,
    mobile,
    address,
    paymentOption
  ) => {
    try {
      const obj = {
        user_id: loginData.id,
        id,
        isAttending,
        mobile,
        address,
      };
      if (getQrData.qualifyCount >= 1 && isAttending == 1) {
        obj.amountPaid = 100;
        // const paymentres = await handleMetamaskPayment(paymentOption, amount)
        const result = await LdpUpdateAction(obj);

        if (result.success) {
          if (isAttending) {
            toast.success(
              "Great! Your details are saved. Now, click “Get Qr Code” button to continue.",
              { duration: 4000 }
            );
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            toast.success("Your response is saved.");
          }

          getLdp2UserData();
          setShowLdpModal(!showLdpModal);
        } else {
          toast.error(result.msg);
        }
      } else {
        obj.amountPaid = 0;

        const result = await LdpUpdateAction(obj);

        if (result.success) {
          if (isAttending) {
            toast.success(
              "Great! Your details are saved. Now, click “Get Qr Code” button to continue.",
              { duration: 4000 }
            );
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            toast.success("Your response is saved.");
          }
          setShowLdpModal(!showLdpModal);
        } else {
          toast.error(result.msg);
        }
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Error updating LDP:", error);
    }
  };

  // handle ldp 2 qualifiers
  const handleUpdateLdp2 = async (
    isAttending,
    id,
    mobile,
    address,
    paymentOption
  ) => {
    try {
      const obj = {
        user_id: loginData.id,
        id,
        isAttending,
        mobile,
        address,
      };
      if (getLdp2Data.qualifyCount >= 1 && isAttending == 1) {
        // const paymentres = await handleMetamaskPayment(paymentOption,amount)
        obj.amountPaid = 1000;
        const result = await Ldp2UpdateAction(obj);

        if (result.success) {
          if (isAttending) {
            toast.success(
              "Great! Your details are saved. Now, click “Get Qr Code” button to continue.",
              { duration: 4000 }
            );
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            toast.success("Your response is saved.");
          }
          setShowLdp2Modal(!showLdp2Modal);
        } else {
          toast.error(result.msg);
        }
      } else {
        obj.amountPaid = 0;

        const result = await Ldp2UpdateAction(obj);
        if (result.success) {
          if (isAttending) {
            toast.success(
              "Great! Your details are saved. Now, click “Get Qr Code” button to continue.",
              { duration: 4000 }
            );
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            toast.success("Your response is saved.");
          }
          setShowLdp2Modal(!showLdp2Modal);
        } else {
          toast.error(result.msg);
        }
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Error updating LDP:", error);
    }
  };

  const isMintFunc = (data) => {
    window.location.href = `${config.baseUrl}mintnft/${data}`;
  };

  return (
    <>
      <Dashboardheader />

      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mb-5">
          <div className="mb-4 sm-data-box">
            <Container className="">
              <Row
                className="justify-content-center  mt-3"
                style={{ scrollbarWidth: "none" }}
              >
                <Col lg={4} md={4} className="mt-4 mb-3">
                  <div className="card card-view">
                    <div className="card-body">
                      <Row>
                        <Col lg={12} xs={12}>
                          <div className="circle-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={22}
                              height={22}
                              fill="#fff"
                              className="bi bi-image"
                              viewBox="0 0 16 16"
                            >
                              {" "}
                              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />{" "}
                              <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />{" "}
                            </svg>
                          </div>
                        </Col>
                        <Col lg={12} xs={12} className="text-center">
                          <div className="card-d">
                            <h3>{dashboarddata.planPurchase || 0}</h3>
                            <p className="mb-0 py-2 px-3">Plan Purchased</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col lg={4} md={4} className="mt-4 mb-3">
                  <div className="card card-view">
                    <div className="card-body">
                      <Row>
                        <Col lg={12} xs={12}>
                          <div className="circle-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 29 26"
                              fill="none"
                            >
                              <path
                                d="M14.3333 17.6667H8.33334C6.47258 17.6667 5.5422 17.6667 4.78514 17.8963C3.08061 18.4134 1.74672 19.7473 1.22965 21.4518C1 22.2089 1 23.1392 1 25M23.6667 25V17M19.6667 21H27.6667M17.6667 7C17.6667 10.3137 14.9804 13 11.6667 13C8.35296 13 5.66667 10.3137 5.66667 7C5.66667 3.68629 8.35296 1 11.6667 1C14.9804 1 17.6667 3.68629 17.6667 7Z"
                                stroke="#F4F4F4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </Col>
                        <Col lg={12} xs={12} className="text-center">
                          <div className="card-d">
                            {/* <h3>${dashboarddata?.referralAmount || 0}</h3> */}
                            <h3>
                              $
                              {Number(
                                dashboarddata.referralAmount || 0
                              ).toFixed(2)}
                            </h3>
                            <p className="mb-0 py-2 px-3">Referral Earning</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} className="mt-4 mb-3">
                  <div className="card card-view">
                    <div className="card-body">
                      <Row>
                        <Col lg={12} xs={12}>
                          <div className="circle-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="22"
                              viewBox="0 0 24 22"
                              fill="none"
                            >
                              <path
                                d="M13.1111 3.22222C13.1111 4.44952 10.3751 5.44444 7.00003 5.44444C3.62495 5.44444 0.888916 4.44952 0.888916 3.22222M13.1111 3.22222C13.1111 1.99492 10.3751 1 7.00003 1C3.62495 1 0.888916 1.99492 0.888916 3.22222M13.1111 3.22222V8.17461C11.7539 8.5822 10.8889 9.19881 10.8889 9.88889M0.888916 3.22222V16.5556C0.888916 17.7829 3.62495 18.7778 7.00003 18.7778C8.47739 18.7778 9.83232 18.5871 10.8889 18.2698V9.88889M0.888916 7.66667C0.888916 8.89397 3.62495 9.88889 7.00003 9.88889C8.47739 9.88889 9.83232 9.69826 10.8889 9.38094M0.888916 12.1111C0.888916 13.3384 3.62495 14.3333 7.00003 14.3333C8.47739 14.3333 9.83232 14.1427 10.8889 13.8254M23.1111 9.88889C23.1111 11.1162 20.3751 12.1111 17 12.1111C13.625 12.1111 10.8889 11.1162 10.8889 9.88889M23.1111 9.88889C23.1111 8.66159 20.3751 7.66667 17 7.66667C13.625 7.66667 10.8889 8.66159 10.8889 9.88889M23.1111 9.88889V18.7778C23.1111 20.0051 20.3751 21 17 21C13.625 21 10.8889 20.0051 10.8889 18.7778V9.88889M23.1111 14.3333C23.1111 15.5606 20.3751 16.5556 17 16.5556C13.625 16.5556 10.8889 15.5606 10.8889 14.3333"
                                stroke="#F4F4F4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </Col>
                        <Col lg={12} xs={12} className="text-center">
                          <div className="card-d">
                            {/* <h3>${dashboarddata.matchingAmount || 0}</h3> */}
                            <h3>
                              $
                              {Number(
                                dashboarddata.matchingAmount || 0
                              ).toFixed(2)}
                            </h3>

                            <p className="mb-0 py-2">Community Rewards</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <br />
          {/* -------------------- */}

          <div className="ActivePlane mb-5">
            <Container className=" package_detail">
              <Row>
                <Col lg={12} className="mb-3">
                  <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <div>
                      <h2 className="text-white " style={{ fontWeight: 600 }}>
                        Subscribed Plan
                      </h2>
                    </div>
                    <div>
                      <Button
                        as={Link}
                        to={`${config.baseUrl}packageplan`}
                        variant="main2"
                        // onClick={redirection}
                        className="px-4 text-white rounded-2  align-content-center"
                        style={{ width: "fit-content" }}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </Col>
                {/* {userdatahistory.length == 0 ? ( */}
                <>
                  {userdata.length == 0 ? (
                    <>
                      <center>
                        <img
                          style={{
                            width: "auto",
                            height: "auto",
                          }}
                          src="assets/images/nodata2.png"
                        />
                        <h4 style={{ color: "#d3d3d3" }}>
                          You haven't purchased any plan yet.
                        </h4>
                      </center>
                    </>
                  ) : (
                    <>
                      <Row className="gy-3">
                        {userdata.map((item, index) => (
                          <Col lg={4} style={{ display: "flex" }}>
                            <div className="card nftplan flex-fill">
                              <div className="card-body p-3  d-flex justify-content-between flex-column">
                                <div className="pt-3 pb-1 px-2">
                                  <h5 className="mb-0 text-white ">
                                    {item.name}
                                  </h5>

                                  <div className="d-flex gap-2 justify-content-between mb-3 mt-2">
                                    {!item.free_signup && <div>
                                      <p
                                        style={{ fontSize: 14 }}
                                        className="mb-0 px-3 py-1 w-auto"
                                      >
                                        ${item.priceUSD}
                                      </p>
                                    </div>}
                                    <div>
                                      <Button
                                        variant="main2"
                                        onClick={() =>
                                          handleShow(item.uniqueId)
                                        }
                                        className="rounded-1 btn-sm mh-auto px-4 "
                                        style={{ fontSize: 14 }}
                                      >
                                        View Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-view text-center p-4 rounded-30">
                                  <img
                                    src={`${config.ipfsurl + item.image}`}
                                    alt={item.image}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </>
                {/* ) */}
                {/* : ( */}
                {/* <>
                    <center>
                      <img
                        style={{
                          width: "auto",
                          height: "auto",

                        }}
                        src="assets/images/nodata2.png"
                      />
                      <h4 style={{ color: "#d3d3d3" }}>No data found</h4>
                    </center>
                  </> */}
                {/* )} */}
              </Row>
            </Container>
          </div>

          {userdatahistory.length !== 0 && (
            <div className="latest_transaction mb-5">
              <Container className="">
                <Row>
                  <Col lg={12} className="mb-3">
                    <h2 className="text-white" style={{ fontWeight: 600 }}>
                      Latest Transactions
                    </h2>
                  </Col>

                  <Col lg={12}>
                    <div className="card card-view">
                      <div className="card-body p-4">
                        <div className="">
                          <div className="table_list">
                            <div className="table-responsive">
                              <table width="100%" className="text-center">
                                <thead
                                  style={{
                                    border:
                                      "1px solid rgba(167, 167, 167, 0.15)",
                                  }}
                                >
                                  <tr>
                                    <th>Purchased On</th>
                                    <th>Package Name</th>
                                    <th>NFT Type</th>
                                    <th>Price (MNT)</th>
                                    <th>Price (USD)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userdatahistory.length == 0 ? (
                                    <tr className="tr_list">
                                      {/* <td></td> */}
                                      {/* <td></td> */}
                                      <td colspan="5">No Data Available</td>
                                      {/* <td></td> */}
                                      {/* <td></td> */}
                                    </tr>
                                  ) : (
                                    userdatahistory.map((item, index) => (
                                      <tr className="tr_list px-2">
                                        <td className="px-4">
                                          {item.datetime}
                                        </td>
                                        <td className="px-4">{item.name}</td>
                                        <td className="px-4">
                                          {item.editionTypeId == 1
                                            ? "Basic Plan"
                                            : item.editionTypeId == 2
                                              ? "Limited Edition"
                                              : ""}
                                        </td>
                                        <td className="px-4">
                                          {item.priceMNT} MNT
                                        </td>
                                        <td className="px-4">
                                          ${item.priceUSD}
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* <div className="table_list">
                          <div className="tr_list border-0 mb-1">
                            <ul>
                              <li>Date</li>
                              <li>Transactions ID</li>
                              <li>Type</li>
                              <li>Name</li>
                              <li>Value</li>
                              <li>Return</li>
                              <li>Status</li>
                            </ul>
                          </div>
                          <div className="tr_list">
                            <ul>
                              <li>07/07/2023</li>
                              <li>123456789</li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                                    fill="#F3BA2F"
                                  />
                                  <path
                                    d="M9.087 10.803L12 7.89L14.9145 10.8045L16.6095 9.1095L12 4.5L7.392 9.108L9.087 10.803ZM4.5 12L6.195 10.305L7.89 12L6.195 13.695L4.5 12ZM9.087 13.197L12 16.11L14.9145 13.1955L16.6095 14.8898L12 19.5L7.392 14.892L7.38975 14.8898L9.087 13.197ZM16.11 12L17.805 10.305L19.5 12L17.805 13.695L16.11 12ZM13.719 11.9985H13.7205V12L12 13.7205L10.2817 12.003L10.2787 12L10.2817 11.9977L10.5825 11.6962L10.7288 11.55L12 10.2795L13.7197 11.9992L13.719 11.9985Z"
                                    fill="white"
                                  />
                                </svg>
                              </li>
                              <li>BNB</li>
                              <li>$100</li>
                              <li>#1800</li>
                              <li>
                                <Button variant="success">Completed</Button>
                              </li>
                            </ul>
                          </div>
                          <div className="tr_list">
                            <ul>
                              <li>07/07/2023</li>
                              <li>123456789</li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="18"
                                  viewBox="0 0 22 18"
                                  fill="none"
                                >
                                  <path
                                    d="M21 5.5H1M1 9.5H4.54668C5.08687 9.5 5.35696 9.5 5.61813 9.54655C5.84995 9.58788 6.0761 9.65635 6.29191 9.75056C6.53504 9.85669 6.75977 10.0065 7.20924 10.3062L7.79076 10.6938C8.24023 10.9935 8.46496 11.1433 8.70809 11.2494C8.9239 11.3437 9.15005 11.4121 9.38186 11.4534C9.64304 11.5 9.91313 11.5 10.4533 11.5H11.5467C12.0869 11.5 12.357 11.5 12.6181 11.4534C12.85 11.4121 13.0761 11.3437 13.2919 11.2494C13.535 11.1433 13.7598 10.9935 14.2092 10.6938L14.7908 10.3062C15.2402 10.0065 15.465 9.85669 15.7081 9.75056C15.9239 9.65635 16.15 9.58788 16.3819 9.54655C16.643 9.5 16.9131 9.5 17.4533 9.5H21M1 4.2L1 13.8C1 14.9201 1 15.4802 1.21799 15.908C1.40973 16.2843 1.71569 16.5903 2.09202 16.782C2.51984 17 3.07989 17 4.2 17L17.8 17C18.9201 17 19.4802 17 19.908 16.782C20.2843 16.5903 20.5903 16.2843 20.782 15.908C21 15.4802 21 14.9201 21 13.8V4.2C21 3.0799 21 2.51984 20.782 2.09202C20.5903 1.7157 20.2843 1.40974 19.908 1.21799C19.4802 1 18.9201 1 17.8 1L4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.7157 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2Z"
                                    stroke="#F4F4F4"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </li>
                              <li>BNB</li>
                              <li>$100</li>
                              <li>#1800</li>
                              <li>
                                <Button variant="danger">Failed</Button>
                              </li>
                            </ul>
                          </div>
                          <div className="tr_list">
                            <ul>
                              <li>07/07/2023</li>
                              <li>123456789</li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                                    fill="#F3BA2F"
                                  />
                                  <path
                                    d="M9.087 10.803L12 7.89L14.9145 10.8045L16.6095 9.1095L12 4.5L7.392 9.108L9.087 10.803ZM4.5 12L6.195 10.305L7.89 12L6.195 13.695L4.5 12ZM9.087 13.197L12 16.11L14.9145 13.1955L16.6095 14.8898L12 19.5L7.392 14.892L7.38975 14.8898L9.087 13.197ZM16.11 12L17.805 10.305L19.5 12L17.805 13.695L16.11 12ZM13.719 11.9985H13.7205V12L12 13.7205L10.2817 12.003L10.2787 12L10.2817 11.9977L10.5825 11.6962L10.7288 11.55L12 10.2795L13.7197 11.9992L13.719 11.9985Z"
                                    fill="white"
                                  />
                                </svg>
                              </li>
                              <li>BNB</li>
                              <li>$100</li>
                              <li>#1800</li>
                              <li>
                                <Button variant="success">Completed</Button>
                              </li>
                            </ul>
                          </div>

                          <div className="tr_list">
                            <ul>
                              <li>07/07/2023</li>
                              <li>123456789</li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="18"
                                  viewBox="0 0 22 18"
                                  fill="none"
                                >
                                  <path
                                    d="M21 5.5H1M1 9.5H4.54668C5.08687 9.5 5.35696 9.5 5.61813 9.54655C5.84995 9.58788 6.0761 9.65635 6.29191 9.75056C6.53504 9.85669 6.75977 10.0065 7.20924 10.3062L7.79076 10.6938C8.24023 10.9935 8.46496 11.1433 8.70809 11.2494C8.9239 11.3437 9.15005 11.4121 9.38186 11.4534C9.64304 11.5 9.91313 11.5 10.4533 11.5H11.5467C12.0869 11.5 12.357 11.5 12.6181 11.4534C12.85 11.4121 13.0761 11.3437 13.2919 11.2494C13.535 11.1433 13.7598 10.9935 14.2092 10.6938L14.7908 10.3062C15.2402 10.0065 15.465 9.85669 15.7081 9.75056C15.9239 9.65635 16.15 9.58788 16.3819 9.54655C16.643 9.5 16.9131 9.5 17.4533 9.5H21M1 4.2L1 13.8C1 14.9201 1 15.4802 1.21799 15.908C1.40973 16.2843 1.71569 16.5903 2.09202 16.782C2.51984 17 3.07989 17 4.2 17L17.8 17C18.9201 17 19.4802 17 19.908 16.782C20.2843 16.5903 20.5903 16.2843 20.782 15.908C21 15.4802 21 14.9201 21 13.8V4.2C21 3.0799 21 2.51984 20.782 2.09202C20.5903 1.7157 20.2843 1.40974 19.908 1.21799C19.4802 1 18.9201 1 17.8 1L4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.7157 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2Z"
                                    stroke="#F4F4F4"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </li>
                              <li>BNB</li>
                              <li>$100</li>
                              <li>#1800</li>
                              <li>
                                <Button variant="main2">In Progress</Button>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                          {/* <div className="text-right">
                          <Button variant="custom" className="text-white px-5">
                            See all
                          </Button>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {/* -------------------- */}
          <div className="adv-banner mb-5 mt-2">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="card card-view overflow-hidden">
                    <div className=" ">
                      <Row className="align-items-center justify-content-center my-5">
                        <Col lg={6} className="text-center">
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin1"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin2"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin3"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin4"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin5"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin6"
                          />

                          <div>
                            {/* <Form.Label className="text-white">
                                    Referral Link
                                  </Form.Label> */}
                            <h3 className="mb-3">Referral Link</h3>
                            <Form.Group
                              controlId="formBasicEmail"
                              className="d-flex align-items-center position-relative px-3"
                            >
                              <Form.Control
                                type="text"
                                value={
                                  config.refLink + loginData?.referral_code
                                }
                                className="mr-3 custom_field"
                              />
                              <CopyToClipboard
                                text={config.refLink + loginData?.referral_code}
                              >
                                <Button
                                  onClick={copieBtn}
                                  variant="btn btn-main2 me-3"
                                >
                                  <span
                                    title="Click to Copy"
                                    className="mr-copylink"
                                    id="token-buy-button"
                                    style={{ cursor: "pointer", color: "#FFF" }}
                                  >
                                    <i className="fa fa-clone "></i>
                                  </span>
                                </Button>
                              </CopyToClipboard>
                              <div className="random-bn"></div>
                            </Form.Group>
                          </div>
                        </Col>
                        {/* <Col lg={6} className="mb-3 text-center">
                                <div className="referral_animation">
                                    <img src="assets/images/referral_animation.gif"  />
                                 </div>
                              </Col> */}
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      {/* --------------------- */}
      <Modal
        show={show}
        onHide={handleClose}
        className="walker_detail"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>WALKER DETAIL</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <img src="assets/images/logo-shape2.png" className="top-right" />
              <img src="assets/images/logo-shape.png" className="botton-left" />
            </div>
            <Row className="gy-2 mt-3">
              {userdata
                .filter((item) => item.uniqueId == userdataid)
                .map((item, index) => (
                  <>
                    <Col lg={6}>
                      <div className="card nftplan h-auto">
                        <div className="card-body p-0 h-100">
                          <div className=" p-3 pb-1">
                            <div>
                              <div className="d-flex flex-wrap justify-content-between align-items-center flex-wrap gap-2 mb-2 mt-2">
                                <div className="">
                                  <h5 className="mb-0 text-white">
                                    {item.name}
                                  </h5>
                                </div>
                                {!item.free_signup && (
                                  <div>
                                    <Button
                                      variant="main2"
                                      className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4"
                                    >
                                      NFT-Id&nbsp;{item.uniqueId}
                                    </Button>
                                  </div>
                                )}
                              </div>
                              {!item.free_signup && <div className="pla "><p
                                className="text-nowrap px-3 py-1"
                                style={{ maxWidth: "220px" }}
                              >
                                ${item.priceUSD} ~ {item.priceMNT} MNT
                              </p></div>}
                            </div>
                          </div>
                          <div className="card-view text-center p-2 rounded-30 h-100">
                            <img
                              src={`${config.ipfsurl + item.image}`} className="h-100"
                              alt={item.image}
                            />
                          </div>
                        </div>
                      </div>


                    </Col>
                    <Col lg={6}>
                      <div className="package_detail">
                        <ul className="">
                          {item.free_signup ? <></> : <li>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                Type of NFT
                              </div>
                              <p className="mb-0">
                                {item.editionTypeId == 1
                                  ? "Basic Nft"
                                  : item.editionTypeId
                                    ? "Limited Edition"
                                    : "Unkonwn Status"}
                              </p>
                            </div>
                          </li>}
                          <li>
                            <div className="d-flex justify-content-between gap-1">
                              <div className="d-flex align-items-center">
                                Subscription Expire{" "}
                                {/* <small>(Starts after App launch)</small> */}
                              </div>
                              <p className="mb-0">{item.expiryDate} </p>
                            </div>
                          </li>
                          <li className="py-3">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center text-wrap">
                                Capacity (Steps)
                              </div>
                              <p className="mb-0">{parseInt(item.capacity)} </p>
                            </div>
                          </li>
                          {/* <li className="py-3">
                      <div className="d-flex justify-content-between">
                        <div>Steps</div>
                        <p className="mb-0">10000</p>
                      </div>
                    </li> */}

                          <li className="py-3">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                Per Day Reward
                              </div>
                              {item.free_signup ? (
                                <p className="mb-0">{item.rewardPercent} MNT</p>
                              ) : (
                                <p className="mb-0">
                                  upto {item.rewardPercent}%
                                </p>
                              )}
                            </div>
                          </li>
                          {!item.free_signup && (
                            <div className=" col-lg-6 w-100">
                              <li>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex align-items-center">
                                    Nft Number
                                  </div>
                                  <p className="mb-0">{item.packagePlanId}</p>
                                </div>
                              </li>
                            </div>
                          )}
                          {item.free_signup && (
                            <li className="py-3">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                  Loyalty Bonus
                                </div>
                                <p className="mb-0">
                                  {item.loyalty_bonus_percent}
                                </p>
                              </div>
                            </li>
                          )}
                          {/* <li>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                Reward Earned
                              </div>
                              <p className="mb-0">
                                {item.totalRewardEarnedOnNft}
                              </p>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </Col>
                    <div className="package_detail">
                      <ul className="mb-0">
                        <div className="row">
                          <div className="col-lg-6 ">
                            {!item.free_signup && (
                              <li>
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center">
                                    {item.isminted == 0
                                      ? "Minting Status"
                                      : "Blockchain View"}
                                  </div>
                                  {item.isminted == 0 ? (
                                    <Button
                                      variant="main2"
                                      onClick={() => isMintFunc(item.uniqueId)}
                                      className="rounded-1 btn-sm mh-auto pt-2 pb-2  py-1 px-4"
                                      style={{height:37}}
                                    >
                                      Proceed to Mint
                                    </Button>
                                  ) : (
                                    <div className="d-flex">
                                      <p className="mb-0 d-flex align-items-center" >
                                        <a
                                          className="mb-0 d-flex align-items-center"
                                          target="_blank"
                                          href={`${item.trxHash}`}
                                          style={{ textDecoration: "none" , height:28}}
                                        >
                                          {item.trxHash?.slice(23, 4) +
                                            "...." +
                                            item.trxHash?.slice(-4)}
                                          {/* View */}
                                        </a>
                                        &emsp;&nbsp;
                                        <CopyToClipboard text={item.trxHash}>
                                          <div>
                                            <i
                                              className="fa fa-clone"
                                              title="Click to Copy"
                                              style={{
                                                cursor: "pointer",
                                                color: "#FFF",
                                              }}
                                              onClick={copieBtn}
                                            ></i>
                                          </div>
                                        </CopyToClipboard>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </li>
                            )}
                          </div>
                          <div className="col-lg-6 ">
                        {!item.free_signup && (
                         <div >
                         <li>
                           <Row className="d-flex justify-content-between">
                             <Col className="d-flex align-items-center">
                               Claim NFT
                             </Col>
                             <Col className="text-end">
                               <Button
                                 variant="main2"
                                 className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4 text-nowrap"
                                 disabled
                               >
                                 Coming Soon
                               </Button>
                             </Col>

                           </Row>

                         </li>
                         {item.free_signup == true &&
                           
                           <div className=" d-flex mb-3 mt-2 pl-0 gap-2 align-items-baseline text-secondary" style={{zIndex:999}}  >
                           <div style={{zIndex:999}}>
                           <input class="form-check-input mt-1" style={{width:15, height:15}} type="checkbox"
                            checked={item.tncchecked}
                             // onChange={inputHandler}
                             name="checkbox" id="flexCheckDefault" />
                           </div>
                           <p style={{ marginTop: "1px",zIndex:999  }} className="mb-0">I acknowledge that I have read and agreed to the <Link target="blank" className="text-primary"
                             to={`${config.baseUrl}plantermsandconditions`}>Terms and condition</Link></p>
                         </div>
                         }
                       </div>


                        )}
                        </div>
                        </div>

                        {/* {item.isminted == 1 ? (
                          <li>
                          <div className="d-flex justify-content-between">
                              <div className="text-warning">
                                Note:-&emsp;&nbsp;
                                </div>
                              <div>
                                NFT minting takes a bit of time to show up in
                                our system. Once it's done, use this Contract
                                Address{" "}
                                {config.contractAddressForMinting?.slice(0, 4) +
                                  "...." +
                                  config.contractAddressForMinting?.slice(-4)}
                                &nbsp;
                                <CopyToClipboard
                                  text={config.contractAddressForMinting}
                                >
                                  <i
                                    className="fa fa-clone"
                                    title="Click to Copy"
                                    style={{
                                      cursor: "pointer",
                                      color: "#FFF",
                                    }}
                                    onClick={copieBtn}
                                  ></i>
                                </CopyToClipboard>{" "}
                                &nbsp; to bring it in, and put your NFT Number
                                i.e for this Nft its ({item.packagePlanId}) into
                                your Metamask wallet. Thanks for your patience!
                                </div>
                                </div>
                                </li>
                        ) : (
                          ""
                        )} */}
                        
                      </ul>

                    </div>
                    {(!item.free_signup && item.tncchecked) ? 
                        <div className=" d-flex mb-3 mt-2 ms-2  pl-0 gap-2 align-items-baseline text-secondary" style={{zIndex:999}}  >
                        <div style={{zIndex:999}}>
                        <input class="form-check-input mt-1" style={{width:15, height:15}} type="checkbox"
                         checked={item.tncchecked}
                          // onChange={inputHandler}
                          name="checkbox" id="flexCheckDefault" />
                        </div>
                        <p style={{ marginTop: "1px",zIndex:999  }} className="mb-0">I acknowledge that I have read and agreed to the <Link target="blank" className="text-primary"
                          to={`${config.baseUrl}plantermsandconditions`}>Terms and condition</Link></p>
                      </div> : ""
                    }
                    {item.free_signup && <div>
                      <p className="mb-2  ms-3 text-light">Review Terms : </p>
                      <ul className="d-flex flex-column gap-2 free-nft">
                        <li className="">
                          Your free subscription will be active for 30 days from the activation date.
                        </li>
                        <li >
                          10 USDT worth of MNT tokens on the Time of sign up will be distributed monthly.
                        </li>
                        <li >
                          Rewards will be distributed according to the number of steps completed each day.
                        </li>
                        <li>To extend the validity of your free sign-up rewards, you need to refer five new accounts within one month after your subscription expires.</li>
                        <li>
                          Your subscription will be extended for an additional 30 days from the last activated referral.
                        </li>
                        <li>
                          You will receive an extra benefit of 50% of the step rewards achieved by your referrals. This benefit will be valid for 30 days and will not be extended.
                        </li>
                        <li>
                          If a free subscriber dose not completes 10000 steps in a day he won't be eligible for receiving loyalty bonus from his direct referrals on that particular day.
                        </li>
                        <li>
                          If you purchase a paid subscription on the same account, your free subscription rewards will stop immediately.
                          {/* Note - This will be valid only for         30 days and will not be extended. */}
                        </li>

                      </ul>
                    </div>}
                  </>
                ))}
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Dashboard;
