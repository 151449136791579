import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Accordion,
  Form,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import { TextField, Box } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { ContactUsAction } from "../Action/user.action";
import "../component/contactus.css";
import { Helmet } from "react-helmet";
import ContactUsMetaTag from "./MetaTags/ContactUsMetaTag";

const Contactus = () => {
  const [userData, setuserData] = useState({
    name: "",
    email: "",
    text: "",
    subject: "",
    mobile_type: "website",
    mobile_detail: "none",
    phone: ""
  });
  const [validationError, setvalidationError] = useState({
    emailError: "",
    nameError: "",
    subjectError: "",
    mobileError: "",
    textError: "",
    userDataError: "",
    deviceError: "",
  });
  const [uuidName, setuuidName] = useState("");
  useEffect(() => { }, []);

  function validate() {
    let emailError = "";
    let nameError = "";
    let subjectError = "";
    let textError = "";
    let deviceError = "";
    let mobileError = "";

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phoneRegex = /^\d{1,15}$/;

    // Name validation (disallow special characters and numbers)
    if (userData.name == "") {
      nameError = "Name is required.";
    }

    // Email validation
    if (userData.email == "") {
      emailError = "Email is required.";
    } else if (!emailRegex.test(userData.email)) {
      emailError = "Invalid email format.";
    }

    // Phone number validation (no more than 15 digits)
    if (userData.phone == "") {
      mobileError = "Phone Number is required.";
    } else if (!phoneRegex.test(userData.phone)) {
      mobileError = "Phone number must contain up to 15 digits.";
    }

    // Subject validation
    if (userData.subject == "") {
      subjectError = "Subject is required.";
    }

    // Text message validation
    if (userData.text == "") {
      textError = "Text message required";
    }

    // Mobile/Laptop details validation
    if (userData.mobile_detail == "") {
      deviceError = "Mobile/Laptop Details are required.";
    }

    if (emailError || nameError || subjectError || textError || deviceError || mobileError) {
      setvalidationError({
        emailError,
        nameError,
        mobileError,
        subjectError,
        textError,
        deviceError,
      });
      return false;
    } else {
      return true;
    }
  }


  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "mobile_type") {
      if (value.toLowerCase() === "android") {
        setuuidName(1);
      } else if (value.toLowerCase() === "ios") {
        setuuidName(2);
      } else {
        setuuidName("");
      }
    }
    setuserData((old) => {
      return { ...old, [name]: value };
    });
  };

  const contactusFunc = async () => {
    console.log(userData);
    const isValid = validate();
    if (!isValid) {
    } else {
      let res2 = await ContactUsAction(userData);
      if (res2.success) {
        toast.success(res2.msg);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res2.msg);
      }
    }
  };

  return (
    <>
      <div className="lighttheme">
        <Header />
        <ContactUsMetaTag />
        <Toaster />

        <section className="position-relative overflow-hidden contactus bg-white">
          {/* <div className="leftshadow">
          <img src="assets/images/blueleft.png" />
        </div> */}
          <div className="shape_bg">
            <img src="assets/images/shape.svg" className="shape" />
            <img src="assets/images/shape1.svg" className="shape1" />
            <img src="assets/images/shape2.svg" className="shape2" />
            {/* <img src="assets/images/shape.svg" className="shape3" />psta
          <img src="assets/images/shape1.svg" className="shape4" /> */}
          </div>
          <Container>
            <Col lg={12}>
              <div>

              </div>
            </Col>
            <Col lg={12} className="mt-3">
              <Zoom>
                <div className="contactus_banner">
                  <div className="banner-img">
                    {/* <img src="assets/images/contact_banner.png" width="100%" /> */}
                    <Fade right>
                      <h1 className="text-white me-4">
                        <b>Contact Us</b>
                      </h1>
                    </Fade>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col lg={12} className="mt-5">
              <Fade right>
                <div className="contactus_form box-shadow">
                  <Container className="">
                    <Row>
                      <Col lg={4} className="mb-4">
                        <TextField
                          id="outlined-helperText"
                          label="Your Name"
                          type="text"
                          placeholder="Enter your name"
                          name="name"
                          onKeyPress={(e) => {
                            const regex = /^[a-zA-Z\s]*$/;
                            if (!regex.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={inputHandler}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                        />
                        <span className="validationError">
                          {validationError.nameError}
                        </span>
                      </Col>
                      <Col lg={4} className="mb-4">
                        <TextField
                          id="outlined-password-input"
                          label="Email Address"
                          type="email"
                          placeholder="Enter your email"
                          onChange={inputHandler}
                          name="email"
                          style={{ width: "100%" }}
                          autoComplete="off"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <span className="validationError">
                          {validationError.emailError}
                        </span>
                      </Col>
                      {/* <Col lg={6} className="mb-3">
                      <TextField
                        id="outlined-password-input"
                        label="Phone Number"
                        placeholder="Enter your Phone Number"
                        name="phone"
                        onChange={inputHandler}
                        type="text"
                        style={{ width: "100%" }}
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                    </Col> */}
                      <Col lg={4} className="mb-4">
                        <TextField
                          id="outlined-password-input"
                          label="Enter Number"
                          type="number"
                          onChange={inputHandler}
                          placeholder="Enter number..."
                          name="phone"
                          autoComplete="off"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <span className="validationError">
                          {validationError.mobileError}
                        </span>
                      </Col>
                      <Col lg={12} className="mb-4">
                        <TextField
                          id="outlined-password-input"
                          label="Subject"
                          type="text"
                          onChange={inputHandler}
                          placeholder="Enter subject..."
                          name="subject"
                          autoComplete="off"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <span className="validationError">
                          {validationError.subjectError}
                        </span>
                      </Col>

                      {/* <Col lg={4} className="mb-4">
                        <FloatingLabel
                          controlId="floatingSelect"
                          label="Select Platform Type"
                        >
                          <Form.Select
                            onChange={inputHandler}
                            name="mobile_type"
                            aria-label="Default select example"
                          >
                            <option value="website">Website</option>
                            <option value="android">Android</option>
                            <option value="ios">IOS</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      {uuidName == 1 || uuidName == "" ? (
                        <Col lg={4} className="mb-4">
                          <TextField
                            id="outlined-password-input"
                            label="Device Name"
                            type="text"
                            onChange={inputHandler}
                            placeholder="Enter mobile/device name..."
                            name="mobile_detail"
                            autoComplete="off"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                           <span className="validationError">
                            {validationError.deviceError}
                          </span>
                        </Col>
                        
                      ) : uuidName == 2 ? (
                        <Col lg={4} className="mb-4">
                          <TextField
                            id="outlined-password-input"
                            label="UDID name"
                            type="text"
                            onChange={inputHandler}
                            placeholder="Enter UDID ..."
                            name="mobile_detail"
                            autoComplete="off"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <span className="validationError">
                            {validationError.deviceError}
                          </span>
                        </Col>
                      ) : (
                        ""
                      )} */}

                      <Col lg={12} className="mb-2">
                        <TextField
                          id="filled-textarea"
                          label="Your Message"
                          placeholder="Message"
                          type="text"
                          onChange={inputHandler}
                          name="text"
                          autoComplete="off"
                          multiline
                          rows={4}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <span className="validationError">
                          {validationError.textError}
                        </span>
                      </Col>

                      <Col lg={12} className="mt-4">
                        <Row>
                          <Col lg={7} className="mb-3">
                            <div>
                              <button
                                type="button"
                                className="text-white px-5  btn btn-main2"
                                onClick={contactusFunc}
                              >
                                Submit
                              </button>
                            </div>
                          </Col>
                          <Col lg={5} className="mb-3"></Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Fade>
            </Col>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  );
};
export default Contactus;
